// export default SellsGraph;
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from "../../api/axios";

const ApexChart = ({ salesData, dates }) => {
  const formattedDates = dates.map(date => {
    const [year, month, day] = date.split('-');
    return `${month}-${day}`; // Format: MM-DD
  });
  const [chartData, setChartData] = useState({
    series: [{
      name: "Recent Month Sales",
      data: salesData
    }],
    options: {
      chart: {
        height: 350,
        type: 'area',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100]
        }
      },
      // title: {
      //   text: 'Sell Graph',
      //   align: 'left'
      // },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        },
      },
      xaxis: {
        categories: formattedDates
      }
    }
  });

  return (
    <div id="chart">
      <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={350} />
    </div>
  );
};

const SellsGraph = () => {
  const [salesData, setSalesData] = useState([]);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    const apiUrl = '/get-month-sell';

    axios.get(apiUrl)
      .then(response => {
        const fetchedData = response.data;
        console.log('Fetched Data:', fetchedData);

        // Create an array of dates representing the recent month
        const recentMonthDates = getRecentMonthDates();
        console.log('Recent Month Dates:', recentMonthDates);

        // Initialize an array to hold sales data for the recent month
        const recentMonthSalesData = Array(recentMonthDates.length).fill(0);

        // Loop through fetched data and update sales data for corresponding dates
        fetchedData.forEach(item => {
          const dateIndex = recentMonthDates.indexOf(getDateString(item.createdAt));
          if (dateIndex !== -1) {
            recentMonthSalesData[dateIndex]++;
          }
        });
        console.log('Recent Month Sales Data:', recentMonthSalesData);

        // Update state with sales data and dates
        setSalesData(recentMonthSalesData);
        setDates(recentMonthDates);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Function to get an array of dates representing the recent month
  const getRecentMonthDates = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const numDays = new Date(year, month + 1, 0).getDate(); // Number of days in the current month
    const dates = [];

    // Loop to generate dates for the recent month
    for (let i = numDays; i > 0; i--) {
      const date = new Date(year, month, i);
      dates.push(getDateString(date));
    }

    return dates.reverse(); // Reverse the array to display recent dates first
  };

  // Function to get date string in 'YYYY-MM-DD' format
  const getDateString = (date) => {
    if (typeof date === 'string') {
      // If date is already in string format, return it
      return date.split('T')[0]; 
    } else if (date instanceof Date) {
      // If date is a Date object, convert it to 'YYYY-MM-DD' format
      return date.toISOString().split('T')[0];
    } else {
      // Handle other cases where date format is invalid
      console.error('Invalid date format:', date);
      return '';
    }
  };

  return (
    <div className="card mb-5 border-0 shadow">
      <div className="card-header bg-white py-3 text-center">
       <b> Sell Graph </b> 
      </div>
      <div className="card-body">
      {salesData.length > 0 && dates.length > 0 ? (
        <ApexChart salesData={salesData} dates={dates} />
      ) : (
        <div>Loading...</div>
      )}
      </div>
      
    </div>
  );
};

export default SellsGraph;

