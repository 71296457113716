import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import "admin-lte/dist/css/adminlte.min.css"; // Import AdminLTE CSS
import "admin-lte/plugins/fontawesome-free/css/all.min.css"; // Import Font Awesome CSS
// import "admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css"; // Import Tempus Dominus Bootstrap 4 CSS
// import $ from "jquery"; // Import jQuery
// import "admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js"; // Import Tempus Dominus Bootstrap 4 JS

const Layout = () => {
  // useEffect(() => {
  //   const disableContextMenu = (e) => {
  //     e.preventDefault();
  //   };

  //   const disableDeveloperTools = (e) => {
  //     if ((e.ctrlKey && e.shiftKey && e.keyCode === 73) || // Ctrl+Shift+I
  //         (e.ctrlKey && e.shiftKey && e.keyCode === 74) || // Ctrl+Shift+J
  //         (e.ctrlKey && e.keyCode === 85)) { // Ctrl+U
  //       e.preventDefault();
  //     }
  //   };

  //   document.addEventListener("contextmenu", disableContextMenu);
  //   document.addEventListener("keydown", disableDeveloperTools);
  //   document.addEventListener("mousedown", disableDeveloperTools);

  //   return () => {
  //     document.removeEventListener("contextmenu", disableContextMenu);
  //     document.removeEventListener("keydown", disableDeveloperTools);
  //     document.removeEventListener("mousedown", disableDeveloperTools);
  //   };
  // }, []);

  return (
    <>
      {/* <Navbar /> */}
      <Sidebar />
      <div
        className="content-wrapper"
        style={{ minHeight: "calc(100vh - 56px)" }}
      >
        <section className="content-header">
          <div className="container-fluid">
            <Outlet />
          </div>
        </section>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Layout;
