import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../style.css";

import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";

const ScheduleNotice = ({
  formData,
  show,
  onClose,
  handleInputChange,
  loggedInUser,
}) => {
  const [publishDateTime, setPublishDateTime] = useState("");
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();
  const futureTime = new Date(now.getTime() + 60 * 60 * 1000);

  const handleSchedule = async () => {
    try {
      const { notice_title, notice_description } = formData;
      const { user_id } = loggedInUser;
      const noticeData = {
        ...formData,
        publish_date_time: publishDateTime || null,
        user_id: loggedInUser.loggedInUser, // Access the user_id property of loggedInUser
        notice_title: notice_title,
        notice_description: notice_description,
      };
      const response = await axios.post("/add-notice", noticeData);
      console.log("Notice scheduling request processed:", response.data);
      toast.success("Notice scheduling request processed");
      onClose();
    } catch (error) {
      console.error("Error scheduling notice:", error);
      toast.error("Failed to schedule notice");
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Schedule Notice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label htmlFor="publishDateTime">Publish Date and Time:</label>
        <Flatpickr
          options={{
            enableTime: true,
            dateFormat: "Y-m-d H:i",
            minDate: "today", // Disable previous dates
            minTime: futureTime.toISOString().split("T")[1].slice(0, 5), // Set min time as time now plus 60 min
            defaultDate: [now.getFullYear(), now.getMonth() + 1, now.getDate()], // Set default date to today
            defaultHour: currentHour, // Set default hour to current hour
            defaultMinute: currentMinute, // Set default minute to current minute
          }}
          id="publishDateTime"
          name="publishDateTime"
          value={publishDateTime}
          onChange={(selectedDates) => setPublishDateTime(selectedDates[0])}
          className="custom-datetime-input"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSchedule}>
          Schedule
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ScheduleNotice;
