import React from 'react'

export const UnfavListAdd = () => {
    // const [formData, setFormData] = useState({
    //     role_id: "",
    //     role_name: "",
    //   });
    //   const [lastFiveRole, setLastFiveRole] = useState([]);
    
    //   const fetchLastFiveRoleData = async () => {
    //     try {
    //       const response = await axios.get("/get-role");
    //       setLastFiveRole(response.data.slice(-5).reverse()); // Reverse to display latest entries first
    //     } catch (error) {
    //       console.error("Error fetching last five role:", error);
    //     }
    //   };
    
    //   // Call the function when the component mounts
    //   useEffect(() => {
    //     fetchLastFiveRoleData();
    //   }, []);
    
    //   const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //       ...formData,
    //       [name]: value,
    //     });
    //   };
    
    //   const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //       const response = await axios.post("/add-role", formData);
    //       console.log("Role added successfully:", response.data);
    //       toast.success("Role added successfully");
    //       fetchLastFiveRoleData();
    //       setFormData({ role_id: "", role_name: "" }); // Clear input fields after successful submission
    //     } catch (error) {
    //       console.error("Error adding category:", error);
    //       toast.error("Failed to add category");
    //     }
    //   };
    
      return (
        // <div className="">
        //   <section className="content">
        //     <div className="container">
        //       <div className="row ">
        //         <div className="">
        //           {/* here add a button to redirect to add category page */}
        //           <Link to="/role" className="btn btn-primary float-right">
        //             See All Role
        //           </Link>
        //         </div>
        //       </div>
        //       <div className="row mt-2">
        //         <div className="col-md-5">
        //           <div className="card shadow p-3 mb-5 bg-body rounded ">
        //             <div className="card-header">
        //               <h3 className="card-title">Add Role</h3>
        //             </div>
        //             <div className="card-body mb-4">
        //               <form onSubmit={handleSubmit}>
        //                 {/* <div className="form-group">
        //                   <label htmlFor="unique_category_id">
        //                     Role ID
        //                   </label>
        //                   <input
        //                     type="text"
        //                     className="form-control"
        //                     id="unique_category_id"
        //                     name="unique_category_id"
        //                     value={formData.unique_category_id}
        //                     onChange={handleInputChange}
        //                     required
        //                   />
        //                 </div> */}
        //                 <div className="form-group">
        //                   <label htmlFor="role_name">Role Name</label>
        //                   <input
        //                     type="text"
        //                     className="form-control"
        //                     id="role_name"
        //                     name="role_name"
        //                     value={formData.role_name}
        //                     onChange={handleInputChange}
        //                     required
        //                   />
        //                 </div>
        //                 <button type="submit" className="btn btn-primary">
        //                   Add Role
        //                 </button>
        //               </form>
        //             </div>
        //           </div>
        //         </div>
        //         <div className="col-md-7 ">
        //           <div className="card shadow p-3 mb-5 bg-body rounded">
        //             <div className="card-header">
        //               <h3 className="card-title">Latest Five Roles</h3>
        //             </div>
        //             <div className="card-body">
        //               <table className="table ">
        //                 <thead>
        //                   <tr>
        //                     <th>Role ID</th>
        //                     <th>Role Name</th>
        //                   </tr>
        //                 </thead>
        //                 <tbody>
        //                   {lastFiveRole.map((role) => (
        //                     <tr key={role.role_id}>
        //                       <td>{role.role_id}</td>
        //                       <td>{role.role_name}</td>
        //                     </tr>
        //                   ))}
        //                 </tbody>
        //               </table>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </section>
    
        //   <ToastContainer />
        // </div>
        <h1>This page is under development</h1>
      );
}

export const UnfavListGet = () => {
    return (
      <div>
        <h1>
            This page is alsmost done. Stay tuned!!!
        </h1>
      </div>
    )
  }
