import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";


const UpdateModal = ({ storeManagerId, transfer, show, onClose }) => {
    const [updatedTransferList, setUpdatedTransferList] = useState({
        transfer_id: "",
        transfer_location: "",
        sell_id: "",
        store_manager_id: "", // Corrected key name
      });
      
  const [selectedSell, setSelectedSell] = useState(null); // Change to object
  const [sells, setSells] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchSells = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-sell");
      setSells(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching roles:", error);
      setLoading(false);
    }
  };

  // Update state when transfer or storeManagerId changes
  useEffect(() => {
    if (transfer) {
      setUpdatedTransferList({
        transfer_id: transfer.transfer_id,
        transfer_location: transfer.transfer_location,
        sell_id: transfer.sell_id,
        store_manager_id: storeManagerId,
      });
    }
    fetchSells();
  }, [transfer, storeManagerId]);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedTransferList({
      ...updatedTransferList,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
  
    try {
        setLoading(true);
        // Make sure a role is selected
        if (!selectedSell) {
            throw new Error('Please select a Sell Id');
        }
  
        const response = await axios.put('/update-transfer', {
            transfer_id: updatedTransferList.transfer_id,
            transfer_location: updatedTransferList.transfer_location,
            sell_id: selectedSell.value,
            store_manager_id: updatedTransferList.store_manager_id,
        });
  
        console.log('Transfer updated successfully:', response.data);
        toast.success("Transfer updated successfully");
        setLoading(false);
        onClose(); // Close the modal
    } catch (error) {
        console.error('Error updating transfer:', error);
        toast.error("Error updating transfer");
        setLoading(false);
    }
};

  

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Transfer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="transfer_id">Transfer ID</label>
            <input
              type="text"
              className="form-control"
              id="transfer_id"
              name="transfer_id"
              value={updatedTransferList.transfer_id}
              disabled
            />
          </div>
          <div className="form-group">
            <label htmlFor="store_manager_id">Store Manager ID</label>
            <input
              type="text"
              className="form-control"
              id="store_manager_id"
              name="store_manager_id"
              value={updatedTransferList.store_manager_id}
              disabled
            />
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="transfer_location">Transfer Location</label>
                <input
                  type="text"
                  className="form-control"
                  id="transfer_location"
                  name="transfer_location"
                  value={updatedTransferList.transfer_location}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="sell_id">Sell Id</label>
                <Select
                  options={sells.map((sell) => ({
                    value: sell.sell_id,
                    label: sell.sell_id,
                  }))}
                  value={selectedSell}
                  onChange={setSelectedSell}
                  placeholder="Select Sell"
                />
              </div>
            </div>

          </div>

          <Button variant="primary" type="submit">
            Update Sell
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateModal;
