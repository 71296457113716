import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link
import "admin-lte/dist/css/adminlte.min.css"; // Import AdminLTE CSS
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "./style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "../api/axios";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isInputFocused, setInputFocused] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    store_name: "",
    origin: "",
    address: "",
    password: "",
    confirmPassword: "",
  });
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Concatenate zip code with address if zip code is provided
      const addressWithZip = formData.zipCode
        ? `${formData.address}, ${formData.zipCode}`
        : formData.address;

      // Update formData with the concatenated address
      const updatedFormData = {
        ...formData,
        address: addressWithZip,
      };

      // Make API call for registration with updated formData
      const response = await axios.post("/add-register", updatedFormData);
      if (response.status === 201) {
        toast.success("Registration successful");
        window.location.href = "/login";
        // Optionally, you can redirect the user to another page upon successful registration
        // history.push("/success");
        setFormData({
          username: "",
          email: "",
          phone: "",
          store_name: "",
          origin: "",
          address: "",
          password: "",
          zipCode: "",
          confirmPassword: "",
        });
      } else {
        toast.error("Failed to register");
      }
    } catch (error) {
      console.error("Error registering:", error);
      toast.error("Failed to register");
    }
  };

  return (
    <div className="container">
      <div className="login-box">
        <h2>Register</h2>
        <form onSubmit={handleSubmit}>
          {/* New input fields */}
          <div className="user-box">
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
            <label>Name</label>
          </div>
          <div className="user-box">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <label>Email</label>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="user-box">
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
                <label>Address</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="user-box">
                <input
                  type="text"
                  name="zipCode"
                  value={formData.zipCode}
                  onChange={handleChange}
                  required
                />
                <label>Zip Code</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="user-box">
                <input
                  type="text"
                  name="store_name"
                  value={formData.store_name}
                  onChange={handleChange}
                  required
                />
                <label>Store Name</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="user-box">
                <input
                  type="text"
                  name="origin"
                  value={formData.origin}
                  onChange={handleChange}
                  required
                />
                <label>Country</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="user-box">
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
                <label>Phone Number</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="user-box">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  required
                  className={isInputFocused ? "input-focused" : ""}
                />

                <button
                  type="button"
                  className="toggle-password icon-button"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                </button>
                <label>Password</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="user-box">
                {/* Modified input field for zipCode */}
                <input
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
                <label>Confirm Password</label>
              </div>
            </div>
          </div>
          <div className="row">
            <Link to="/login">Login here</Link>
          </div>
          <button type="submit" className="btn btn-primary mt-2">
            Register
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
