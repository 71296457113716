import React, { useState, useEffect, useContext, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom"; // Import Link
// import DeleteConfirmationModal from "./DeleteConfirmationModal";
// import UpdateModal from "./UpdateModal";
import axios from "../../api/axios";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import MinimumTopThreeWasteModal from "./MinimumTopThreeWasteModal";
import { AuthContext } from "../../context/auth";
import Error403 from "../../components/Error/403";
import DataTable from "../../components/table";

export const InventoryAdd = ({ loggedInUser }) => {
  const [formData, setFormData] = useState({
    inventory_id: "",
    block_name: "",
    vendor_id: "",
    vendor_name: "",
    product_name: "",
    user_id: "",
    requisition_id: "",
    purchase_id: "",
    return_id: "",
    waste_id: "",
    category_name: "",
    quantity: "",
    init_slab_length: "",
    init_slab_weidth: "",
  });
  const { user } = useContext(AuthContext);

  const [lastFiveInventories, setLastFiveInventories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedRequisition, setselectedRequisition] = useState(null);
  const [requisitions, setRequisitions] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [selectedBlock, setselectedBlock] = useState(null);

  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("Block");
  const [lengthUnit, setLengthUnit] = useState("mm");

  const fetchLastFiveInventories = async () => {
    try {
      const response = await axios.get("/get-all-inventory");
      setLastFiveInventories(response.data.slice(-5).reverse());
    } catch (error) {
      console.error("Error fetching last five inventories:", error);
    }
  };

  useEffect(() => {
    fetchLastFiveInventories();
    fetchCategories();
    fetchVendors();
    fetchRequisitions();
    fetchBlocks();
  }, []);
  console.log(user);
  if (user) {
    const access = user.user?.role.find((role) => role.name === "inventory");
    if (!access.allow) {
      return <Error403 />;
    }
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const inchesToMillimeters = (inches) => {
      return inches * 25.4; // 1 inch = 25.4 millimeters
    };

    const routes = {
      Block: "/add-block",
      Slab: "/add-inventory",
      Tools: "/add-inventory",
      Chemical: "/add-inventory",
    };

    const route = routes[selectedType];

    // Prepare data based on selected type
    let data = {
      user_id: loggedInUser,
      vendor_id: selectedVendor?.value.id,
      vendor_name: selectedVendor?.value.name,
      category_name: selectedCategory?.value,
      quantity: formData.quantity, // Include quantity field
      inventory_id: "", // Set inventory_id to empty here
      type: selectedType, // Include selectedType here
    };

    // Additional adjustments based on selected type
    if (selectedType === "Block") {
      // Convert dimensions to millimeters if lengthUnit is Inch
      const length =
        lengthUnit === "Inch"
          ? inchesToMillimeters(formData.init_length)
          : formData.init_length;
      const width =
        lengthUnit === "Inch"
          ? inchesToMillimeters(formData.init_width)
          : formData.init_width;
      const height =
        lengthUnit === "Inch"
          ? inchesToMillimeters(formData.init_height)
          : formData.init_height;

      data = {
        ...data,
        block_id: "", // Set inventory_id to empty here
        block_name: formData.block_name,
        init_length: length,
        init_width: width,
        category_id: selectedCategory?.value,
        init_height: height,
        init_weight: formData.init_weight,
        parent_id: selectedBlock?.value,
        requisition_id: selectedRequisition?.value,
        purchase_id: formData.purchase_id,
      };
      delete data.category_name;
      delete data.inventory_id;
    } else if (selectedType === "Slab") {
      // Convert dimensions to millimeters if lengthUnit is Inch
      const length =
        lengthUnit === "Inch"
          ? inchesToMillimeters(formData.init_slab_length)
          : formData.init_slab_length;
      const width =
        lengthUnit === "Inch"
          ? inchesToMillimeters(formData.init_slab_weidth)
          : formData.init_slab_weidth;

      data = {
        ...data,
        product_name: formData.product_name,
        init_slab_length: length,
        init_slab_weidth: width,
        block_id: selectedBlock?.value,
      };
    } else if (selectedType === "Tools") {
      data = {
        ...data,
        product_name: formData.product_name,
        requisition_id: selectedRequisition?.value,
        purchase_id: formData.purchase_id,
      };
    } else if (selectedType === "Chemical") {
      data = {
        ...data,
        product_name: formData.product_name,
        requisition_id: selectedRequisition?.value,
        purchase_id: formData.purchase_id,
      };
    }

    try {
      const response = await axios.post(route, data);
      console.log("Item added successfully:", response.data);
      toast.success("Item added successfully");
      fetchLastFiveInventories();
      setFormData({
        inventory_id: "",
        block_name: "",
        vendor_id: "",
        vendor_name: "",
        product_name: "",
        user_id: "",
        requisition_id: "",
        purchase_id: "",
        return_id: "",
        waste_id: "",
        category_name: "",
        quantity: "",
        init_slab_length: "",
        init_slab_width: "",
      });
    } catch (error) {
      console.error("Error adding item:", error);
      toast.error("Failed to add item");
    }
  };

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-category");
      setCategories(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
    }
  };

  const fetchRequisitions = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-all-requisition");
      setRequisitions(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
    }
  };

  const fetchBlocks = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-all-block");
      setBlocks(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching blocks:", error);
      setLoading(false);
    }
  };

  const fetchVendors = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-all-vendor");
      setVendors(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching vendors:", error);
      setLoading(false);
    }
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const renderFormFields = () => {
    switch (selectedType) {
      case "Block":
        return (
          <div className="row">
            <div className="row mb-2">
              <div className="col-md-3">
                <label htmlFor="unit">Select Unit</label>

                <Select
                  className=""
                  options={[
                    { value: "MM", label: "MM" },
                    { value: "Inch", label: "Inch" },
                  ]}
                  value={{ value: lengthUnit, label: lengthUnit }}
                  onChange={(selectedOption) =>
                    setLengthUnit(selectedOption.value)
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="block_name">Block Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ex- Alaxa White Block"
                    id="block_name"
                    name="block_name"
                    value={formData.block_name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="init_length">Block Length</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={`Enter Block Length (${lengthUnit})`}
                    id="init_length"
                    name="init_length"
                    value={formData.init_length}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="init_width">Block Width</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={`Enter Block Width (${lengthUnit})`}
                    id="init_width"
                    name="init_width"
                    value={formData.init_width}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="purchase_id">Purchase Id</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ex- 12-1234"
                    id="purchase_id"
                    name="purchase_id"
                    value={formData.purchase_id}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="init_height">Block Height</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ex- 50 "
                    id="init_height"
                    name="init_height"
                    value={formData.init_height}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="init_weight">Block Weight (TON)</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ex- 105 TON"
                    id="init_weight"
                    name="init_weight"
                    value={formData.init_weight}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="parent_id">Block Parent Id (if any)</label>
                  <Select
                    options={blocks?.map((block) => ({
                      value: block.block_id,
                      label: block.block_id,
                    }))} // Map roles to options
                    value={selectedBlock} // Change to selectedRole
                    onChange={setselectedBlock} // Change to setSelectedRole
                    placeholder="Select Parent" // Placeholder text
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="requisition_id">Requisition Id</label>
                  <Select
                    options={requisitions.map((requisition) => ({
                      value: requisition.requisition_id,
                      label: requisition.requisition_id,
                    }))} // Map roles to options
                    value={selectedRequisition} // Change to selectedRole
                    onChange={setselectedRequisition} // Change to setSelectedRole
                    placeholder="Select Requisition" // Placeholder text
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case "Slab":
        return (
          <div className="row">
            <div className="row mb-2">
              <div className="col-md-3">
                <label htmlFor="unit">Select Unit</label>

                <Select
                  className=""
                  options={[
                    { value: "MM", label: "MM" },
                    { value: "Inch", label: "Inch" },
                  ]}
                  value={{ value: lengthUnit, label: lengthUnit }}
                  onChange={(selectedOption) =>
                    setLengthUnit(selectedOption.value)
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="product_name">Slab Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ex- Alaxa white slab"
                    id="product_name"
                    name="product_name"
                    value={formData.product_name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="init_slab_length">Slab Length</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ex- 60mm"
                    id="init_slab_length"
                    name="init_slab_length"
                    value={formData.init_slab_length}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="init_slab_weidth">Slab Width</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ex- 35mm"
                    id="init_slab_weidth"
                    name="init_slab_weidth"
                    value={formData.init_slab_weidth}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label htmlFor="parent_id">Slab Parent Id</label>
                  <Select
                    options={blocks?.map((block) => ({
                      value: block.block_id,
                      label: block.block_id,
                    }))} // Map roles to options
                    value={selectedBlock} // Change to selectedRole
                    onChange={setselectedBlock} // Change to setSelectedRole
                    placeholder="Select Parent" // Placeholder text
                  />
                </div>
              </div>
            </div>

            <div className="row">
              {/* <div className="col-md-4">
              <div className="form-group">
                  <label htmlFor="init__slab_height">Slab Height</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ex- 0.1 mm"
                    id="init__slab_height"
                    name="init__slab_height"
                    value={formData.init__slab_height}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-4">
              <div className="form-group">
                  <label htmlFor="init_slab_weight">Slab Weight</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ex- 12-1234"
                    id="init_slab_weight"
                    name="init_slab_weight"
                    value={formData.init_slab_weight}
                    onChange={handleInputChange}
                  />
                </div>
              </div> */}
            </div>
          </div>
        );
      case "Tools":
        return (
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="product_name">Tool Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ex- Hammer"
                  id="product_name"
                  name="product_name"
                  value={formData.product_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="requisition_id">Requisition Id</label>
                <Select
                  options={requisitions.map((requisition) => ({
                    value: requisition.requisition_id,
                    label: requisition.requisition_id,
                  }))} // Map roles to options
                  value={selectedRequisition} // Change to selectedRole
                  onChange={setselectedRequisition} // Change to setSelectedRole
                  placeholder="Select Requisition" // Placeholder text
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="purchase_id">Purchase Id</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ex- 12-1234"
                  id="purchase_id"
                  name="purchase_id"
                  value={formData.purchase_id}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {/* Add other Tools-specific fields here */}
          </div>
        );
      case "Chemical":
        return (
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="product_name">Chemical Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ex- H2SO4"
                  id="product_name"
                  name="product_name"
                  value={formData.product_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="requisition_id">Requisition Id</label>
                <Select
                  options={requisitions.map((requisition) => ({
                    value: requisition.requisition_id,
                    label: requisition.requisition_id,
                  }))} // Map roles to options
                  value={selectedRequisition} // Change to selectedRole
                  onChange={setselectedRequisition} // Change to setSelectedRole
                  placeholder="Select Requisition" // Placeholder text
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="purchase_id">Purchase Id</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ex- 12-1234"
                  id="purchase_id"
                  name="purchase_id"
                  value={formData.purchase_id}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            {/* Add other Chemical-specific fields here */}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="">
      <section className="content">
        <div className="container">
          <div className="row ">
            <div className="">
              <Link to="/inventory" className="btn btn-primary float-right">
                See All Inventory
              </Link>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded ">
                <div className="card-header">
                  <h3 className="card-title">Add Inventory</h3>
                </div>
                <div className="card-body mb-4">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>Select Type</label>
                      <div className="form-check form-check-inline ml-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inventoryType"
                          id="block"
                          value="Block"
                          checked={selectedType === "Block"}
                          onChange={handleTypeChange}
                        />
                        <label className="form-check-label" htmlFor="block">
                          Block
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inventoryType"
                          id="slab"
                          value="Slab"
                          checked={selectedType === "Slab"}
                          onChange={handleTypeChange}
                        />
                        <label className="form-check-label" htmlFor="slab">
                          Slab
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inventoryType"
                          id="tools"
                          value="Tools"
                          checked={selectedType === "Tools"}
                          onChange={handleTypeChange}
                        />
                        <label className="form-check-label" htmlFor="tools">
                          Tools
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inventoryType"
                          id="chemical"
                          value="Chemical"
                          checked={selectedType === "Chemical"}
                          onChange={handleTypeChange}
                        />
                        <label className="form-check-label" htmlFor="chemical">
                          Chemical
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="vendor_id">Vendor</label>
                          <Select
                            options={vendors.map((vendor) => ({
                              value: {
                                id: vendor.vendor_id,
                                name: vendor.vendor_name,
                              },
                              label: vendor.vendor_name,
                            }))}
                            value={selectedVendor}
                            onChange={setSelectedVendor}
                            placeholder="Select vendor"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="category_name">Category Name</label>
                          <Select
                            options={categories.map((category) => ({
                              value: category.unique_category_id,
                              label: category.category_name,
                            }))} // Map roles to options
                            value={selectedCategory} // Change to selectedRole
                            onChange={setSelectedCategory} // Change to setSelectedRole
                            placeholder="Select Category" // Placeholder text
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="quantity">Quantity</label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Ex- 1200"
                            id="quantity"
                            name="quantity"
                            value={formData.quantity}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    {renderFormFields()}

                    <button type="submit" className="btn btn-primary">
                      Add Item
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12"></div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </div>
  );
};

export const InventoryGet = () => {
  const [inventories, setInventories] = useState([]);
  const [inventoryToUpdate, setInventoryToUpdate] = useState(null);
  const [inventoryIdToDelete, setInventoryIdToDelete] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterSlabLength, setFilterSlabLength] = useState("");
  const [filterSlabWidth, setFilterSlabWidth] = useState("");
  const [blocks, setBlocks] = useState([]);
  const [blockIdToDelete, setBlockIdToDelete] = useState(null);
  const [blockToUpdate, setBlockToUpdate] = useState(null);
  const [lengthUnit, setLengthUnit] = useState("mm");
  const [showSlabLengthInput, setShowSlabLengthInput] = useState(false);
  const [showSlabWidthInput, setShowSlabWidthInput] = useState(false);
  const [selectedType, setSelectedType] = useState("Inventory");
  const [showModal, setShowModal] = useState(false);
  const [topThreeInventories, setTopThreeInventories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [categories, setCategories] = useState([]);

  const { user } = useContext(AuthContext);
  const table = useRef(null);

  const itemsPerPage = 15;

  const fetchInventories = async () => {
    try {
      const response = await axios.get(
        `/get-inventory?page=${currentPage}&itemsPerPage=${itemsPerPage}`
      );

      setInventories(response.data?.inventories || []);
    } catch (error) {
      console.error("Error fetching inventories:", error);
    }
  };

  const fetchBlocks = async () => {
    try {
      const response = await axios.get(
        `/get-block?page=${currentPage}&itemsPerPage=${itemsPerPage}`
      );
      setBlocks(response.data?.blockList || []);
    } catch (error) {
      console.error("Error fetching blocks:", error);
    }
  };

  useEffect(() => {
    if (selectedType === "Inventory") {
      fetchInventories();
    } else {
      fetchBlocks();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, selectedType]);

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-category");
      setCategories(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInventories();
    fetchBlocks();
    fetchCategories();
  }, []);

  if (user) {
    const access = user.user?.role.find((role) => role.name === "inventory");
    if (!access.allow) {
      return <Error403 />;
    }
  }

  const filteredInventoryLists = inventories.filter((inventory) => {
    const {
      inventory_date = inventory.createdAt,
      inventory_id,
      vendor_id,
      product_name,
      user_id,
      requisition_id,
      purchase_id,
      category_name,
      quantity,
      init_slab_length,
      init_slab_weidth,
    } = inventory;
    const searchTerms = searchTerm.toLowerCase();

    // Convert each field to string and make it lowercase
    const id = inventory_id ? inventory_id.toString().toLowerCase() : "";
    const productName = product_name
      ? product_name.toString().toLowerCase()
      : "";
    const vendorId = vendor_id ? vendor_id.toString().toLowerCase() : "";
    const userId = user_id ? user_id.toString().toLowerCase() : "";
    const qty = quantity ? quantity.toString().toLowerCase() : "";
    const requisitionId = requisition_id
      ? requisition_id.toString().toLowerCase()
      : "";
    const purchaseId = purchase_id ? purchase_id.toString().toLowerCase() : "";
    const categoryName = category_name
      ? category_name.toString().toLowerCase()
      : "";
    const slabLength = init_slab_length
      ? init_slab_length.toString().toLowerCase()
      : "";
    const slabWidth = init_slab_weidth
      ? init_slab_weidth.toString().toLowerCase()
      : "";
    const inventoryDate = inventory_date
      ? new Date(inventory_date).toLocaleDateString().toLowerCase()
      : "";

    // Check if any of the fields contain the search term
    return (
      id.includes(searchTerms) ||
      productName.includes(searchTerms) ||
      vendorId.includes(searchTerms) ||
      userId.includes(searchTerms) ||
      qty.includes(searchTerms) ||
      requisitionId.includes(searchTerms) ||
      purchaseId.includes(searchTerms) ||
      categoryName.includes(searchTerms) ||
      slabLength.includes(searchTerms) ||
      slabWidth.includes(searchTerms) ||
      inventoryDate.includes(searchTerms)
    );
  });

  const filteredBlockLists = blocks.filter((block) => {
    const {
      block_id,
      block_name,
      init_length,
      init_width,
      unique_category_id,
      init_height,
      init_weight,
      parent_id,
      requisition_id,
      purchase_id,
    } = block;
    const searchTerms = searchTerm.toLowerCase();

    // Convert each field to string and make it lowercase
    const id = block_id ? block_id.toString().toLowerCase() : "";
    const blockName = block_name ? block_name.toString().toLowerCase() : "";
    const length = init_length ? init_length.toString().toLowerCase() : "";
    const width = init_width ? init_width.toString().toLowerCase() : "";
    const categoryId = unique_category_id
      ? unique_category_id.toString().toLowerCase()
      : "";
    const height = init_height ? init_height.toString().toLowerCase() : "";
    const weight = init_weight ? init_weight.toString().toLowerCase() : "";
    const parentId = parent_id ? parent_id.toString().toLowerCase() : "";
    const requisitionId = requisition_id
      ? requisition_id.toString().toLowerCase()
      : "";
    const purchaseId = purchase_id ? purchase_id.toString().toLowerCase() : "";

    // Check if any of the fields contain the search term
    return (
      id.includes(searchTerms) ||
      blockName.includes(searchTerms) ||
      length.includes(searchTerms) ||
      width.includes(searchTerms) ||
      categoryId.includes(searchTerms) ||
      height.includes(searchTerms) ||
      weight.includes(searchTerms) ||
      parentId.includes(searchTerms) ||
      requisitionId.includes(searchTerms) ||
      purchaseId.includes(searchTerms)
    );
  });

  const convertToInch = (value) => {
    // Conversion logic from MM to Inch
    return value / 25.4;
  };

  const convertTosqm = (value) => {
    // Conversion logic from MM to Inch
    return value / 1000000;
  };

  const convertTosqft = (value) => {
    // Conversion logic from MM to Inch

    const valuSqInch = value / 645.16;
    return valuSqInch / 144;
  };

  const renderLength = (value) => {
    if (typeof value !== "number") {
      return "N/A";
    }
    if (lengthUnit === "Inch") {
      return convertToInch(value).toFixed(2) + " inch";
    }
    return value.toFixed(2) + " mm";
  };

  const renderArea = (value) => {
    if (typeof value !== "number") {
      return "N/A";
    }
    if (lengthUnit === "MM") {
      return convertTosqm(value).toFixed(2) + " sqM";
    } else if (lengthUnit === "Inch") {
      return convertTosqft(value).toFixed(2) + " sqFt";
    }
    return value.toFixed(2) + " mm";
  };

  const renderHeight = (value) => {
    if (typeof value !== "number") {
      return "N/A";
    }
    if (lengthUnit === "Inch") {
      return convertToInch(value).toFixed(2) + " inch";
    }
    return value.toFixed(2) + " mm";
  };

  const renderWidth = (value) => {
    if (typeof value !== "number") {
      return "N/A";
    }
    if (lengthUnit === "Inch") {
      return convertToInch(value).toFixed(2) + " inch";
    }
    return value.toFixed(2) + " mm";
  };

  const toggleSlabInput = () => {
    setShowSlabLengthInput(!showSlabLengthInput);
    setShowSlabWidthInput(!showSlabWidthInput);
  };

  const calculateWaste = (
    initialLength,
    initialWidth,
    filterLength,
    filterWidth
  ) => {
    const deductedLength = initialLength - filterLength;
    const deductedWidth = initialWidth - filterWidth;
    return {
      deductedLength: Math.max(0, deductedLength), // Non-negative waste value
      deductedWidth: Math.max(0, deductedWidth), // Non-negative waste value
    };
  };

  // Function to handle the "Apply" button click
  // Inside handleApplyFilters function, after calculating top three inventories
  const handleApplyFilters = () => {
    // Filter inventories based on slab length and width
    const filteredInventories = inventories.filter((inventory) => {
      const initialLength = inventory.init_slab_length;
      const initialWidth = inventory.init_slab_weidth;
      return (
        initialLength >= filterSlabLength && initialWidth >= filterSlabWidth
      );
    });

    // Sort filtered inventories based on waste calculation
    const sortedInventories = filteredInventories.sort((a, b) => {
      const wasteA = calculateWaste(
        a.init_slab_length,
        a.init_slab_weidth,
        +filterSlabLength,
        +filterSlabWidth
      );
      const wasteB = calculateWaste(
        b.init_slab_length,
        b.init_slab_weidth,
        +filterSlabLength,
        +filterSlabWidth
      );
      return wasteA - wasteB;
    });

    // Get top three inventories with minimum waste
    const topThreeInventories = sortedInventories
      .slice(0, 1)
      .map((inventory) => ({
        ...inventory,
        ...calculateWaste(
          inventory.init_slab_length,
          inventory.init_slab_weidth,
          +filterSlabLength,
          +filterSlabWidth
        ),
      }));

    // Update state with topThreeInventories
    setTopThreeInventories(topThreeInventories);
  };

  function handleSetSelectedType(value) {
    setSelectedType(value);
    setCurrentPage(1);
    setTotalItems(0);
  }

  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="row">
                  <div className="">
                    <Link
                      to="/inventory/add"
                      className="btn btn-primary float-right"
                    >
                      Add Item
                    </Link>
                  </div>
                  <div className="col-md-4"></div>
                </div>
                <div className="card-header mt-2">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="card-title">Inventory Item List</h3>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 py-3">
                  <div className="col-md-8">
                    <div>
                      <input
                        type="radio"
                        id="inventory"
                        name="type"
                        value="Inventory"
                        checked={selectedType === "Inventory"}
                        onChange={(e) => handleSetSelectedType(e.target.value)}
                      />
                      <label htmlFor="inventory" className="mr-2">
                        Inventory
                      </label>
                      <input
                        type="radio"
                        id="block"
                        name="type"
                        value="Block"
                        checked={selectedType === "Block"}
                        onChange={(e) => handleSetSelectedType(e.target.value)}
                      />
                      <label htmlFor="block">Block</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-2 ml-2 justify-content-between">
                  <div className="col-md-3">
                    <label htmlFor="unit">Select Unit</label>
                    <Select
                      options={[
                        { value: "MM", label: "MM" },
                        { value: "Inch", label: "Inch" },
                      ]}
                      value={{ value: lengthUnit, label: lengthUnit }}
                      onChange={(selectedOption) =>
                        setLengthUnit(selectedOption.value)
                      }
                    />
                  </div>
                  {/* <div className="col-md-6"></div> */}
                  <div className="col-md-3">
                    <div className="input-group">
                      <label htmlFor="slabLength">
                        <FontAwesomeIcon
                          icon={faFilter}
                          className="mr-1"
                          onClick={toggleSlabInput}
                        />
                      </label>
                    </div>

                    {showSlabLengthInput && (
                      <div>
                        <div>
                          <label>Slab Length</label>
                          <input
                            type="text"
                            className="form-control"
                            value={filterSlabLength}
                            onChange={(e) =>
                              setFilterSlabLength(e.target.value)
                            }
                          />
                        </div>
                        <div>
                          <label>Slab Width</label>
                          <input
                            type="text"
                            className="form-control"
                            value={filterSlabWidth}
                            onChange={(e) => setFilterSlabWidth(e.target.value)}
                          />
                        </div>

                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            handleApplyFilters();
                            setShowModal(true);
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="card-body table-responsive">
                  {selectedType === "Inventory" && inventories.length > 0 && (
                    <DataTable
                      data={inventories}
                      ref={table}
                      customPaginate
                      setCurrentPage={setCurrentPage}
                      totalItems={totalItems}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">Inventory Id</th>
                          <th className="text-center">Product Name</th>
                          <th className="text-center">Length</th>
                          <th className="text-center">Width</th>
                          {/* <th className="text-center">Height</th>
                            <th className="text-center">Weight</th> */}
                          <th className="text-center">Area</th>
                          <th className="text-center">Purchase Id</th>
                          <th className="text-center">Vendor Id</th>
                          <th className="text-center">Vendor Name</th>
                          <th className="text-center">Requisition Id</th>
                          <th className="text-center">Category Name</th>
                          <th className="text-center">Quantity</th>
                          <th className="text-center">Total Area</th>
                          <th className="text-center">User Id</th>
                          <th className="text-center">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredInventoryLists.map((inventory) => (
                          <tr key={inventory.inventory_id}>
                            <td className="text-secondary text-center">
                              {inventory.inventory_id || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {inventory.product_name || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {renderLength(inventory.init_slab_length) ||
                                "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {renderLength(inventory.init_slab_weidth) ||
                                "N/A"}
                            </td>
                            {/* <td className="text-secondary text-center">
                                {inventory.init_height || "N/A"}
                              </td>
                              <td className="text-secondary text-center">
                                {inventory.init_weight || "N/A"}
                              </td> */}
                            <td className="text-secondary text-center">
                              {renderArea(
                                inventory.init_slab_length *
                                  inventory.init_slab_weidth
                              ) || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {inventory.purchase_id || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {inventory.vendor_id || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {inventory.vendor_name || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {inventory.requisition_id || "N/A"}
                            </td>
                            {/* <td className="text-secondary text-center">
                                {inventory.category_name || "N/A"}
                              </td> */}
                            <td className="text-secondary text-center">
                              {categories.find(
                                (category) =>
                                  category.unique_category_id ===
                                  inventory.category_name
                              )?.category_name || "Unknown"}
                            </td>
                            <td className="text-secondary text-center">
                              {inventory.quantity || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {renderArea(
                                inventory.quantity *
                                  (inventory.init_slab_length *
                                    inventory.init_slab_weidth)
                              ) || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {inventory.user_id || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {inventory.createdAt
                                ? new Date(
                                    inventory.createdAt
                                  ).toLocaleDateString()
                                : "N/A"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </DataTable>
                  )}
                  {blocks.length > 0 && selectedType === "Block" && (
                    <DataTable
                      data={blocks}
                      ref={table}
                      customPaginate
                      setCurrentPage={setCurrentPage}
                      totalItems={totalItems}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">Block Id</th>
                          <th className="text-center">Block Name</th>
                          <th className="text-center">Length</th>
                          <th className="text-center">Width</th>
                          <th className="text-center">Height</th>
                          <th className="text-center">Weight</th>
                          <th className="text-center">Category Id</th>
                          <th className="text-center">Category Name</th>
                          <th className="text-center">Parent Id</th>
                          <th className="text-center">Requisition Id</th>
                          <th className="text-center">Purchase Id</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredBlockLists.map((block) => (
                          <tr key={block.block_id}>
                            <td className="text-secondary text-center">
                              {block.block_id || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {block.block_name || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {renderLength(+block.init_length) || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {renderWidth(+block.init_width) || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {renderHeight(+block.init_height) || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {block.init_weight || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {block.category_id || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {categories.find(
                                (category) =>
                                  category.unique_category_id ===
                                  block.category_id
                              )?.category_name || "Unknown"}
                            </td>
                            <td className="text-secondary text-center">
                              {block.parent_id || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {block.requisition_id || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {block.purchase_id || "N/A"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Update and Delete modals */}
      {/* <UpdateModal
        requisition={inventoryToUpdate}
        show={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        onConfirm={handleConfirmUpdate}
      />

      <DeleteConfirmationModal
        requisitionIdToDelete={inventoryIdToDelete}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      /> */}

      <MinimumTopThreeWasteModal
        show={showModal}
        onClose={() => setShowModal(false)}
        topThreeInventories={topThreeInventories} // Pass the topThreeInventories state as a prop
        filterSlabLength={filterSlabLength}
        filterSlabWidth={filterSlabWidth}
      />

      <ToastContainer />
    </div>
  );
};
