import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom"; // Import Link
import axios from "../../api/axios";
import FileUpload from "../../components/fileUpload/FileUpload";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { HexColorPicker } from "react-colorful";

export const SettingsIndexAdd = () => {
  const [formData, setFormData] = useState({
    settings_id: "",
    application_name: "",
    application_logo: "",
    application_address: "",
    application_color: "",
    application_color_gradient: "",
  });

  const [files, setFiles] = useState([]);

  useEffect(() => {
    // Fetch the existing settings data
    const fetchSettings = async () => {
      try {
        const response = await axios.get("get-settings");
        const existingSettings = response.data && response.data[0];
        if (existingSettings) {
          setFormData(existingSettings);
        }
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  const getContrastYIQ = (hexcolor) => {
    try {
      hexcolor = hexcolor.replace("#", "");
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 160 ? "#333333" : "#ffffff";
    } catch (err) {
      console.log(err);
      return "#333333";
    }
  };

  function hexToRgb(hex) {
    const bigint = parseInt(hex.replace("#", ""), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  }

  function rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  function adjustBrightness({ r, g, b }, percent) {
    return {
      r: Math.min(255, Math.max(0, r + percent)),
      g: Math.min(255, Math.max(0, g + percent)),
      b: Math.min(255, Math.max(0, b + percent)),
    };
  }

  function generateGradient(baseColor) {
    const rgbBase = hexToRgb(baseColor);
    const startColor = rgbToHex(
      ...Object.values(adjustBrightness(rgbBase, -20))
    );
    const midColor = rgbToHex(...Object.values(adjustBrightness(rgbBase, -10)));
    const endColor = rgbToHex(...Object.values(adjustBrightness(rgbBase, -30)));

    return `linear-gradient(90deg, ${startColor} 0%, ${midColor} 49%, ${endColor} 100%)`;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleColorChange = (color) => {
    setFormData((e) => ({
      ...e,
      application_color: color,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        ...formData,
        application_color_contrast: getContrastYIQ(formData.application_color),
        application_color_gradient: generateGradient(
          formData.application_color
        ),
      };
      const response = await axios.post("/add-settings", {
        ...data,
        application_logo: files[0],
      });
      console.log("Settings added successfully:", response.data);
      toast.success("Settings added successfully");
      setFormData({
        settings_id: "",
        application_name: "",
        application_logo: "",
        application_address: "",
        application_color: "",
        application_color_gradient: "",
      });
    } catch (error) {
      console.error("Error adding settings:", error);
      toast.error("Failed to add settings");
    }
  };
  console.log(formData);

  return (
    <div className="">
      <section className="content">
        <div className="container">
          <div className="row ">
            <div className="">
              <Link to="/settings/get" className="btn btn-primary float-right">
                See All Configuration
              </Link>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-8 mx-auto">
              <div className="card shadow p-3 mb-5 bg-body rounded ">
                <div className="card-header">
                  <h3 className="card-title">Configure Site</h3>
                </div>
                <div className="card-body mb-4">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="application_name">Application Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex- Dhaka"
                        id="application_name"
                        name="application_name"
                        value={formData.application_name}
                        onChange={handleInputChange}
                        
                      />
                    </div>

                    <div className="form-group">
                      <FileUpload
                        label={"Upload application logo"}
                        updateFilesCb={setFiles}
                        maxFileSizeInBytes={5000000}
                        preSelectedFiles={files}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="application_address">
                        Application Address
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex- Dhaka"
                        id="application_address"
                        name="application_address"
                        value={formData.application_address}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="application_color">
                        Application Color
                      </label>
                      <HexColorPicker
                        color={formData.application_color}
                        onChange={handleColorChange}
                      />
                      ;
                    </div>

                    <button type="submit" className="btn btn-primary">
                      Confirm
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export const SettingsIndexGet = () => {
  const [settings, setSettings] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [settingsToDelete, setSettingsToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const fetchSettings = async () => {
    try {
      const response = await axios.get("/get-settings");
      setSettings(response.data);
    } catch (error) {
      console.error("Error fetching settings:", error);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleDelete = (settings_id) => {
    setSettingsToDelete(settings_id);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete("delete-settings", {
        data: { settings_id: settingsToDelete },
      });
      console.log("Settings deleted successfully:", response.data);
      fetchSettings();
      toast.success("Settings deleted successfully");
    } catch (error) {
      console.error("Error deleting settings:", error);
      toast.error("Failed to delete settings");
    } finally {
      setShowDeleteModal(false);
    }
  };

  const filteredSettings = settings.filter((setting) => {
    const {
      settings_id,
      application_name,
      application_logo,
      application_address,
      application_color,
    } = setting;
    const searchTerms = searchTerm.toLowerCase();

    const id = settings_id ? settings_id.toString().toLowerCase() : "";
    const applicationName = application_name
      ? application_name.toString().toLowerCase()
      : "";
    const applicationLogo = application_logo
      ? application_logo.toString().toLowerCase()
      : "";
    const applicationAddress = application_address
      ? application_address.toString().toLowerCase()
      : "";
    const applicationColor = application_color
      ? application_color.toString().toLowerCase()
      : "";

    return (
      id.includes(searchTerms) ||
      applicationName.includes(searchTerms) ||
      applicationLogo.includes(searchTerms) ||
      applicationAddress.includes(searchTerms) ||
      applicationColor.includes(searchTerms)
    );
  });

  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="row">
                  <div className="">
                    <Link
                      to="/settings"
                      className="btn btn-primary float-right"
                    >
                      Add Configuration
                    </Link>
                  </div>
                  <div className="col-md-4"></div>
                </div>
                <div className="card-header mt-2">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="card-title">Configuration List</h3>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 py-3">
                  <div className="col-md-8"></div>
                  <div className="col-md-4">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body table-responsive">
                  {filteredSettings.length === 0 ? (
                    <div className="text-center">
                      No item found in the list.
                    </div>
                  ) : (
                    <table className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th className="text-center">Settings ID</th>
                          <th className="text-center">Application Name</th>
                          <th className="text-center">Application Logo</th>
                          <th className="text-center">Application Address</th>
                          <th className="text-center">Application Color</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredSettings.map((setting) => (
                          <tr key={setting.settings_id}>
                            <td className="text-secondary text-center">
                              {setting.settings_id}
                            </td>
                            <td className="text-secondary text-center">
                              {setting.application_name}
                            </td>
                            <td className="text-secondary text-center">
                              {setting.application_logo || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {setting.application_address}
                            </td>
                            <td className="text-secondary text-center">
                              <div
                                style={{
                                  backgroundColor: setting.application_color,
                                  width: "30px",
                                  height: "30px",
                                  margin: "auto",
                                  border: "1px solid #000",
                                }}
                              ></div>
                            </td>
                            <td className="text-center">
                              <button
                                className="btn btn-danger btn-sm"
                                title="Delete"
                                onClick={() =>
                                  handleDelete(setting.settings_id)
                                }
                              >
                                <i className="fas fa-trash-alt mr-1"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <DeleteConfirmationModal
        settingsToDelete={settingsToDelete}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};
