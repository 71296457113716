import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import ImageLoader from "../ImageLoader";
import "./fileUpload.css";
import axios from "../../api/axios";

const FileUpload = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes,
  preSelectedFiles,
  reset,
  ...otherProps
}) => {
  const fileInputField = useRef(null);
  const [files, setFiles] = useState(preSelectedFiles || []);
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (reset) {
      setFiles([]);
    }
  }, [reset]);

  const addNewFilesLocal = async (file) => {
    try {
      setStatus("Uploading...");
      const data = new FormData();
      data.append("files", file);
      const resp = await axios({
        method: "post",
        url: `endpoint/file-upload`,
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => res.data);
      if (resp.success) {
        setStatus("");
        return resp.files[0]?.filename;
      } else {
        console.log(resp);

        throw new Error("Something Went Wrong " + resp);
      }
    } catch (err) {
      setStatus(err.message);
    }
  };

  const callUpdateFilesCb = (files) => {
    updateFilesCb(files);
  };

  const handleNewFileUpload = async (e) => {
    try {
      const { files: newFiles } = e.target;
      const uploadFunction = addNewFilesLocal;
      if (newFiles.length) {
        const promises = [];
        for (const file of newFiles) {
          if (file.size <= maxFileSizeInBytes) {
            promises.push(uploadFunction(file));
          } else {
            toast.error(
              `File (${file.name}) size is too large (Max ${
                maxFileSizeInBytes * 1024 * 1024
              }mb)`
            );
          }
          Promise.all(promises).then((res) => {
            if (res) {
              const uploadedFiles = res.filter((x) => x && x.length > 0);
              const updatedFileList = otherProps.multiple
                ? [...files, ...uploadedFiles]
                : [...uploadedFiles];
              setFiles(updatedFileList);
              callUpdateFilesCb(updatedFileList);
            }
          });
        }
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const removeFile = async (fileName) => {
    try {
      setStatus("Deleting...");
      const { success, err } = await axios({
        method: "DELETE",
        url: `endpoint/file-upload?name=${fileName}`,
      }).then((res) => res.data);
      if (success) {
        const filteredItem = files.filter((item) => item !== fileName);
        setFiles(filteredItem);
        callUpdateFilesCb(filteredItem);
      } else {
        toast.error(err);
      }
    } catch (err) {
      console.log(err);
      toast.error(`Something Went Wrong - ${err.message}`);
    }
    setStatus("");
  };

  function isImageFile(filename) {
    // List of image extensions to check against
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
    // Get the file extension by splitting the filename at the last dot
    const extension = filename?.split(".").pop().toLowerCase();
    // Check if the file extension is in the imageExtensions list
    return imageExtensions.includes(extension);
  }

  return (
    <>
      <div className="mb-3">
        <label className="form-label">{label}</label>
        <input
          className="form-control"
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          {...otherProps}
        />
      </div>
      {status.length > 0 && <div className="text-danger my-2">{status}</div>}
      {files.length > 0 && (
        <div className="d-flex">
          {files.map((file, index) => {
            return (
              <div className="previewContainer" key={file + index}>
                <div className="previewItem">
                  {isImageFile(file) ? (
                    <ImageLoader
                      className="imagePreview"
                      src={file}
                      alt={file}
                      width={100}
                      height={100}
                    />
                  ) : (
                    <i className="fa-solid fa-file"></i>
                  )}
                  <aside>
                    <i
                      className="fa fa-trash"
                      onClick={() => removeFile(file)}
                    ></i>
                  </aside>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default FileUpload;
