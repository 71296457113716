import React, { useState, useEffect, useContext, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import axios from "../../api/axios";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import "flatpickr/dist/flatpickr.css"; // Default styles for Flatpickr
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import UpdateModal from "./UpdateModal";
import { AuthContext } from "../../context/auth";
import Error403 from "../../components/Error/403";
import DataTable from "../../components/table";

const getStatusBadge = (status) => {
  let badgeClass = "";
  switch (status) {
    case "Pending":
      badgeClass = "badge-warning";
      break;
    case "Accepted":
      badgeClass = "badge-success";
      break;
    case "Rejected":
      badgeClass = "badge-danger";
      break;
    default:
      badgeClass = "badge-info";
  }
  return <span className={`badge ${badgeClass}`}>{status}</span>;
};

export const RequisitionIndexAdd = ({ loggedInUser }) => {
  const [formData, setFormData] = useState({
    requisition_id: "",
    product_name: "",
    startDate: "",
    endDate: "",
    quantity: "",
    unique_category_id: "",
    user_id: "",
  });
  const [modifyAccess, setModifyAccess] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastFiveRequisitions, setLastFiveRequisitions] = useState([]);
  const { user } = useContext(AuthContext);

  const fetchLastFiveRequisitions = async () => {
    try {
      const response = await axios.get("get-all-requisition");
      setLastFiveRequisitions(response.data.slice(-3).reverse());
    } catch (error) {
      console.error("Error fetching last five requisitions:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-category");
      setCategories(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLastFiveRequisitions();
    fetchCategories();
  }, []);

  useEffect(() => {
    if (user) {
      const access = user.user?.role.find(
        (role) => role.name === "requisition"
      );
      const modifyaccess = user.user?.role.find(
        (role) => role.name === "modifyRequisition"
      );

      setModifyAccess(modifyaccess ? modifyaccess.allow : false);
      if (!access.allow) {
        return <Error403 />;
      }
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (selectedDates) => {
    setDateRange(selectedDates);
    setFormData({
      ...formData,
      startDate: selectedDates[0],
      endDate: selectedDates[1],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/add-requisition", {
        ...formData,
        user_id: loggedInUser,
        unique_category_id: selectedCategory.value,
      });
      toast.success("Requisition request added successfully");
      fetchLastFiveRequisitions();
      setFormData({
        requisition_id: "",
        product_name: "",
        startDate: "",
        endDate: "",
        quantity: "",
        unique_category_id: "",
        user_id: "",
      });
      setDateRange([null, null]);
    } catch (error) {
      console.error("Error adding Requisition request:", error);
      toast.error("Failed to add Requisition request");
    }
  };

  return (
    <div className="">
      <section className="content">
        <div className="container">
          <div className="row ">
            {modifyAccess && (
              <div className="">
                <Link to="/requisition" className="btn btn-primary float-right">
                  See All Requisition Requests
                </Link>
              </div>
            )}
          </div>
          <div className="row mt-2">
            <div className="col-md-5">
              <div className="card shadow p-3 mb-5 bg-body rounded ">
                <div className="card-header">
                  <h3 className="card-title">Request Requisition</h3>
                </div>
                <div className="card-body mb-4">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="product_name">Product Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="product_name"
                        name="product_name"
                        value={formData.product_name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label>Urgency Date</label>
                      <div className="form-control p-0">
                        <Flatpickr
                          options={{ mode: "range" }}
                          value={dateRange}
                          onChange={handleDateChange}
                          className="form-control"
                          placeholder="Select Date Range"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="quantity">Quantity</label>
                      <input
                        type="number"
                        className="form-control"
                        id="quantity"
                        name="quantity"
                        value={formData.quantity}
                        onChange={handleInputChange}
                        required
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="unique_category_id">Category</label>
                      <Select
                        options={categories.map((category) => ({
                          value: category.unique_category_id,
                          label: category.category_name,
                        }))}
                        value={selectedCategory}
                        onChange={setSelectedCategory}
                        placeholder="Select Category"
                        isLoading={loading}
                      />
                    </div>

                    <button type="submit" className="btn btn-primary">
                      Request Requisition
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="card last-five-list-card shadow p-3 mb-5 bg-body rounded">
                <div className="card-header">
                  <h3 className="card-title">
                    Latest Three Requisition Requests
                  </h3>
                </div>
                <div className="card-body table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-center">Requisition ID</th>
                        <th className="text-center">Product Name</th>
                        <th className="text-center">Start Date</th>
                        <th className="text-center">End Date</th>
                        <th className="text-center">Quantity</th>
                        <th className="text-center">Category</th>
                        <th className="text-center">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lastFiveRequisitions.map((requisition) => (
                        <tr key={requisition.requisition_id}>
                          <td className="text-secondary text-center">
                            {requisition.requisition_id}
                          </td>
                          <td className="text-secondary text-center">
                            {requisition.product_name}
                          </td>
                          <td className="text-secondary text-center">
                            {new Date(
                              requisition.startDate
                            ).toLocaleDateString()}
                          </td>
                          <td className="text-secondary text-center">
                            {new Date(requisition.endDate).toLocaleDateString()}
                          </td>
                          <td className="text-secondary text-center">
                            {requisition.quantity}
                          </td>
                          <td className="text-secondary text-center">
                            {categories.find(
                              (category) =>
                                category.unique_category_id ===
                                requisition.unique_category_id
                            )?.category_name || "Unknown"}
                          </td>
                          <td className="text-secondary text-center">
                            {getStatusBadge(requisition.status)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export const RequisitionIndexGet = () => {
  const [requisitions, setRequisitions] = useState([]);
  const [requisitionToUpdate, setRequisitionToUpdate] = useState(null);
  const [requisitionIdToDelete, setRequisitionIdToDelete] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const table = useRef(null);

  const itemsPerPage = 15;

  const fetchRequisitions = async () => {
    try {
      const response = await axios.get(
        `/get-requisition?page=${currentPage}&itemsPerPage=${itemsPerPage}`
      );
      setRequisitions(response.data?.requisitionList || []);
    } catch (error) {
      console.error("Error fetching requisitions:", error);
    }
  };
  const fetchCategories = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-category");
      setCategories(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching categories:", error);
      setLoading(false);
    }
  };
  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchRequisitions();
    fetchCategories();
  }, []);

  if (user) {
    const access = user.user?.role.find(
      (role) => role.name === "modifyRequisition"
    );
    if (!access || !access.allow) {
      return <Error403 />;
    }
  }

  const handleDelete = (requisition_id) => {
    setRequisitionIdToDelete(requisition_id);
    setShowDeleteModal(true);
  };

  const handleEdit = (requisition) => {
    if (requisition.status === "Pending") {
      setRequisitionToUpdate(requisition);
      setShowUpdateModal(true);
    } else {
      toast.error("Status is not pending.");
    }
  };

  const handleStatusUpdate = async (requisitionId, status) => {
    if (status === "Accepted" || status === "Rejected") {
      try {
        const response = await axios.put("/update-requisition-status", {
          requisition_id: requisitionId,
          status: status,
        });
        console.log("Requisition status updated successfully:", response.data);
        fetchRequisitions();
        toast.success("Requisition status updated successfully");
      } catch (error) {
        console.error("Error updating requisition status:", error);
        toast.error("Failed to update requisition status");
      }
    } else {
      toast.error("Status already updated, cannot change status");
    }
  };

  const handleConfirmUpdate = async () => {
    try {
      const response = await axios.put("/update-requisition", {
        data: requisitionToUpdate,
      });

      if (response.status === 200) {
        console.log("Requisition request updated successfully:", response.data);
        fetchRequisitions();
        toast.success("Requisition request updated successfully");
        setShowUpdateModal(false);
      }
    } catch (error) {
      console.error("Error updating requisition:", error);
      toast.error("Failed to update requisition");
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete("/delete-requisition", {
        data: { requisition_id: requisitionIdToDelete },
      });
      console.log("Requisition request deleted successfully:", response.data);
      fetchRequisitions();
      toast.success("Requisition request deleted successfully");
    } catch (error) {
      console.error("Error deleting requisition:", error);
      toast.error("Failed to delete requisition");
    } finally {
      setShowDeleteModal(false);
    }
  };

  const filteredRequisitionLists = requisitions.filter((requisition) => {
    const {
      requisition_date = requisition.createdAt,
      requisition_id,
      product_name,
      startDate,
      endDate,
      quantity,
      unique_category_id,
      status,
    } = requisition;
    const searchTerms = searchTerm.toLowerCase();

    // Convert each field to string and make it lowercase
    const id = requisition_id ? requisition_id.toString().toLowerCase() : "";
    const productName = product_name
      ? product_name.toString().toLowerCase()
      : "";
    const start = startDate
      ? new Date(startDate).toLocaleDateString().toLowerCase()
      : "";
    const end = endDate
      ? new Date(endDate).toLocaleDateString().toLowerCase()
      : "";
    const qty = quantity ? quantity.toString().toLowerCase() : "";
    const category = unique_category_id
      ? unique_category_id.toString().toLowerCase()
      : "";
    const requisitionStatus = status ? status.toString().toLowerCase() : "";
    const requisitionDate = requisition_date
      ? new Date(requisition_date).toLocaleDateString().toLowerCase()
      : "";

    // Check if any of the fields contain the search term
    return (
      id.includes(searchTerms) ||
      productName.includes(searchTerms) ||
      start.includes(searchTerms) ||
      end.includes(searchTerms) ||
      qty.includes(searchTerms) ||
      category.includes(searchTerms) ||
      requisitionStatus.includes(searchTerms) ||
      requisitionDate.includes(searchTerms)
    );
  });

  console.log(user);
  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="row">
                  <div className="">
                    <Link
                      to="/requisition/add"
                      className="btn btn-primary float-right"
                    >
                      Add Requisition Request
                    </Link>
                  </div>
                  <div className="col-md-4"></div>
                </div>
                <div className="card-header mt-2">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="card-title">Requisition Request List</h3>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 py-3">
                  <div className="col-md-8"></div>
                  <div className="col-md-4">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body table-responsive">
                  {requisitions.length > 0 && (
                    <DataTable
                      data={requisitions}
                      customPaginate
                      setCurrentPage={setCurrentPage}
                      totalItems={totalItems}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">Requisition ID</th>
                          <th className="text-center">
                            Requisition Request User ID
                          </th>
                          <th className="text-center">Product Name</th>
                          <th className="text-center">Start Date</th>
                          <th className="text-center">End Date</th>
                          <th className="text-center">Quantity</th>
                          <th className="text-center">Category</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Action</th>
                          <th className="text-center">Status Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredRequisitionLists.map((requisition) => (
                          <tr key={requisition.requisition_id}>
                            <td className="text-secondary text-center">
                              {requisition.requisition_id}
                            </td>
                            <td className="text-secondary text-center">
                              {requisition.user_id || "Unknowen"}
                            </td>
                            <td className="text-secondary text-center">
                              {requisition.product_name}
                            </td>
                            <td className="text-secondary text-center">
                              {new Date(
                                requisition.startDate
                              ).toLocaleDateString()}
                            </td>
                            <td className="text-secondary text-center">
                              {new Date(
                                requisition.endDate
                              ).toLocaleDateString()}
                            </td>
                            <td className="text-secondary text-center">
                              {requisition.quantity}
                            </td>
                            {/* <td className="text-secondary text-center">
                              {requisition.unique_category_id}
                            </td> */}
                            <td className="text-secondary text-center">
                              {categories.find(
                                (category) =>
                                  category.unique_category_id ===
                                  requisition.unique_category_id
                              )?.category_name || "Unknown"}
                            </td>

                            <td className="text-secondary text-center">
                              {getStatusBadge(requisition.status)}
                            </td>
                            <td className="text-secondary text-center">
                              <button
                                className="btn btn-primary btn-sm mr-2"
                                title="Edit"
                                onClick={() => handleEdit(requisition)}
                              >
                                <i className="fas fa-edit mr-1"></i>
                              </button>
                              <button
                                className="btn btn-danger btn-sm"
                                title="Delete"
                                onClick={() =>
                                  handleDelete(requisition.requisition_id)
                                }
                              >
                                <i className="fas fa-trash-alt mr-1"></i>
                              </button>
                            </td>
                            <td className="text-secondary text-center">
                              <button
                                className="btn btn-success btn-sm mr-2"
                                title="Accept"
                                onClick={() =>
                                  handleStatusUpdate(
                                    requisition.requisition_id,
                                    "Accepted"
                                  )
                                }
                              >
                                <i className="fas fa-check mr-1"></i>
                              </button>
                              <button
                                className="btn btn-danger btn-sm"
                                title="Reject"
                                onClick={() =>
                                  handleStatusUpdate(
                                    requisition.requisition_id,
                                    "Rejected"
                                  )
                                }
                              >
                                <i className="fas fa-times mr-1"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <UpdateModal
        requisition={requisitionToUpdate}
        show={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        onConfirm={handleConfirmUpdate}
      />

      <DeleteConfirmationModal
        requisitionIdToDelete={requisitionIdToDelete}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};
