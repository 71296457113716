// App.js

import React, { useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import Layout from "./layout/Layout";
import Navbar from "./layout/Navbar";
import Register from "./pages/Register";
import axios from "./api/axios";
import { ToastContainer, toast } from "react-toastify";
import routesConfig from "./routesConfig"; // Import the routes configuration
import FileUploadPage from "./pages/file";
import { AuthProvider } from "./context/auth";
import { SettingsProvider } from "./context/settings";
import { Appearance } from "./components/appearance";

function App() {
  const [loggedInUser, setLoggedInUser] = useState(null);
  const location = useLocation();
  const currentPage = location.pathname.substring(1);

  const handleLogin = (user_id) => {
    console.log("User ID:", user_id);
    setLoggedInUser(user_id);
    sessionStorage.setItem("user_id", user_id);
  };

  const handleLogout = async () => {
    try {
      const user_id = sessionStorage.getItem("user_id");
      if (!user_id) throw new Error("User ID not found in session storage");
      console.log("Session before clearing:", sessionStorage);

      const response = await axios
        .post("/logout", { user_id })
        .then((e) => e.data);
      if (response.success) {
        setLoggedInUser(null);
        sessionStorage.clear();
        toast.success("Logout Successful");
      } else {
        throw new Error("Logout failed");
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
    location.href = "/login";
  };

  const isLoggedIn = () => {
    return loggedInUser !== null || sessionStorage.getItem("user_id") !== null;
  };


  return (
    <AuthProvider>
      <SettingsProvider>
        <Appearance/>
      {(isLoggedIn() || sessionStorage.getItem("user_id")) && (
        <Navbar
          loggedInUser={sessionStorage.getItem("user_id")}
          onLogout={handleLogout}
          currentPage={currentPage}
        />
      )}
      <Routes>
        {isLoggedIn() ? (
          <Route path="/" element={<Layout />}>
            {routesConfig.map(({ path, component: Component }) => (
              <Route
                key={path}
                path={path}
                element={
                  <Component loggedInUser={sessionStorage.getItem("user_id")} />
                }
              />
            ))}
          </Route>
        ) : (
          <Route path="*" element={<Navigate to="/login" />} />
        )}
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route
          path="/register"
          element={isLoggedIn() ? <Navigate to="/login" /> : <Register />}
        />
        <Route path="/file" element={<FileUploadPage />} />
      </Routes>
      <ToastContainer />
      
      </SettingsProvider>
    </AuthProvider>

  );
}

export default App;
