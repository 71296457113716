import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom"; // Import Link
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import UpdateModal from "./UpdateModal";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const RoleAdd = () => {
  const [formData, setFormData] = useState({
    role_id: "",
    role_name: "",
  });
  const [lastFiveRole, setLastFiveRole] = useState([]);

  const fetchLastFiveRoleData = async () => {
    try {
      const response = await axios.get("/get-role");
      setLastFiveRole(response.data.slice(-5).reverse()); // Reverse to display latest entries first
    } catch (error) {
      console.error("Error fetching last five role:", error);
    }
  };

  // Call the function when the component mounts
  useEffect(() => {
    fetchLastFiveRoleData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/add-role", formData);
      console.log("Role added successfully:", response.data);
      toast.success("Role added successfully");
      fetchLastFiveRoleData();
      setFormData({ role_id: "", role_name: "" }); // Clear input fields after successful submission
    } catch (error) {
      console.error("Error adding category:", error);
      toast.error("Failed to add category");
    }
  };

  return (
    <div className="">
      <section className="content">
        <div className="container">
          <div className="row ">
            <div className="">
              {/* here add a button to redirect to add category page */}
              <Link to="/role" className="btn btn-primary float-right">
                See All Role
              </Link>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-5">
              <div className="card shadow p-3 mb-5 bg-body rounded ">
                <div className="card-header">
                  <h3 className="card-title">Add Role</h3>
                </div>
                <div className="card-body mb-4">
                  <form onSubmit={handleSubmit}>
                    {/* <div className="form-group">
                      <label htmlFor="unique_category_id">
                        Role ID
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="unique_category_id"
                        name="unique_category_id"
                        value={formData.unique_category_id}
                        onChange={handleInputChange}
                        required
                      />
                    </div> */}
                    <div className="form-group">
                      <label htmlFor="role_name">Role Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ex- Admin"
                        id="role_name"
                        name="role_name"
                        value={formData.role_name}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Add Role
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-7 ">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="card-header">
                  <h3 className="card-title">Latest Five Roles</h3>
                </div>
                <div className="card-body">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th className="text-center">Role ID</th>
                        <th className="text-center">Role Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lastFiveRole.map((role) => (
                        <tr key={role.role_id}>
                          <td className="text-secondary text-center">
                            {role.role_id}
                          </td>
                          <td className="text-secondary text-center">
                            {role.role_name}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export const RoleGet = () => {
  const [roles, setRoles] = useState([]);
  const [roleToUpdate, setRoleToUpdate, setUpdateRoleName] = useState(null);
  const [roleIdToDelete, setRoleIdToDelete] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchRoles = async () => {
    try {
      const response = await axios.get("/get-role");
      setRoles(response.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleDelete = (role_id) => {
    setRoleIdToDelete(role_id);
    setShowDeleteModal(true);
  };

  const handleEdit = (roles) => {
    const { role_id, role_name } = roles;
    const role = {
      role_id,
      role_name,
    };
    setRoleToUpdate(role);
    setShowUpdateModal(true);
  };

  const handleConfirmUpdate = async () => {
    try {
      const response = await axios.put("/update-role", {
        data: {
          role_id: roleToUpdate.role_id,
          category_name: roleToUpdate.role_name,
        },
      });

      if (response.status === 200) {
        console.log("Role updated successfully:", response.data);
        toast.success("Role updated successfully");

        setShowUpdateModal(false);
        fetchRoles();
      }
    } catch (error) {
      console.error("Error updating role:", error);
      toast.error("Failed to update role");
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete("delete-role", {
        data: { role_id: roleIdToDelete },
      });
      console.log("Role deleted successfully:", response.data);
      fetchRoles();
      toast.success("Role deleted successfully");
    } catch (error) {
      console.error("Error deleting Role:", error);
      toast.error("Failed to delete Role");
    } finally {
      setShowDeleteModal(false);
    }
  };

  const filteredRoles = roles.filter((role) => {
    const { role_id, role_name } = role;
    const searchTerms = searchTerm.toLowerCase();

    // Convert each field to string and make it lowercase
    const id = role_id ? role_id.toString() : "";
    const name = role_name ? role_name.toString().toLowerCase() : "";

    // Check if any of the fields contain the search term
    return id.includes(searchTerms) || name.includes(searchTerms);
  });

  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="row">
                  <div className="">
                    {/* here add a button to redirect to add category page */}
                    <Link
                      to="/role/add"
                      className="btn btn-primary float-right"
                    >
                      Add Role
                    </Link>
                  </div>
                </div>
                <div className="card-header mt-2">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="card-title">Roles</h3>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 py-3">
                  <div className="col-md-8"></div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control float-right"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
                <div className="card-body">
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">ID</th>
                        <th className="text-center">Name</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredRoles.map((roles) => (
                        <tr key={roles.role_id}>
                          <td className="text-secondary text-center">
                            {roles.role_id}
                          </td>
                          <td className="text-secondary text-center">
                            {roles.role_name}
                          </td>
                          <td className="text-secondary text-center">
                            <FontAwesomeIcon icon="fa-solid fa-house" />
                            <button
                              className="btn btn-primary btn-sm mr-2"
                              title="Edit"
                              onClick={() => handleEdit(roles)}
                            >
                              <i className="fas fa-edit mr-1"></i>
                            </button>

                            <button
                              className="btn btn-danger btn-sm"
                              title="Delete"
                              onClick={() => handleDelete(roles.role_id)}
                            >
                              <i className="fas fa-trash-alt mr-1"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <UpdateModal
        role={roleToUpdate}
        show={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        onConfirm={handleConfirmUpdate}
      />
      <DeleteConfirmationModal
        roleIdToDelete={roleIdToDelete}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};
