// UpdateModal.js
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateModal = ({ role, show, onClose }) => {
  const [updatedRole, setUpdatedRole] = useState({
    role_id: "",
    role_name: "",
  });

  // Use useEffect to update the state when `role` prop changes
  useEffect(() => {
    if (role) {
      setUpdatedRole({
        role_id: role.role_id,
        role_name: role.role_name,
      });
    }
  }, [role]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedRole({
      ...updatedRole,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send PUT request to update category
      const response = await axios.put("/update-role", updatedRole);
      console.log("Role updated successfully:", response.data);
      onClose();
      toast.success("Role updated successfully");
    } catch (error) {
      console.error("Error updating Role:", error);
      toast.error("Failed to update Role");
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="unique_category_id">Role ID</label>
            <input
              type="text"
              className="form-control"
              id="role_id"
              name="role_id"
              value={updatedRole.role_id}
              disabled // Keep it disabled to prevent changes
            />
          </div>

          <div className="form-group">
            <label htmlFor="category_name">Role Name</label>
            <input
              type="text"
              className="form-control"
              id="role_name"
              name="role_name"
              value={updatedRole.role_name}
              onChange={handleInputChange} // Allow changes
              required
            />
          </div>
          <Button variant="primary" type="submit">
            Update Role
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateModal;
