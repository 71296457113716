import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { AuthContext } from "../context/auth";
import { SettingsContext } from "../context/settings";

const Sidebar = () => {
  const [current, setCurrent] = useState(0);
  const { user } = useContext(AuthContext);
  const settings = useContext(SettingsContext);

  const filterAccess = (access, menu) => {
    let filteredMenu = [];
    if (access) {
      for (let i = 0; i < menu.length; i++) {
        const el = menu[i];
        const res = access.find((x) => x.name === el.target);
        if (res && res.allow) {
          filteredMenu.push(el);
        }
      }
    }
    return filteredMenu;
  };
  const menu = [
    {
      name: "Dashboard",
      link: "/dashboard",
      icon: <i className="fas fa-chart-line mr-1"></i>,
      target: "dashboard",
    },
    {
      name: "Category",
      link: "/category",
      icon: <i className="nav-icon fas fa-tags"></i>,
      target: "category",
    },
    {
      name: "Inventory",
      link: "/inventory/add",
      icon: <i className="nav-icon fas fa-boxes"></i>,
      target: "inventory",
    },
    {
      name: "Sell",
      link: "/sell",
      icon: <i className="nav-icon fas fa-coins"></i>,
      target: "sell",
    },
    {
      name: "All Users ",
      link: "/all-user",
      icon: <i className="nav-icon fas fa-users"></i>,
      target: "allUser",
    },
    {
      name: "Vendor ",
      link: "/vendor",
      icon: <i className="nav-icon fas fa-handshake"></i>,
      target: "vendor",
    },
    {
      name: "Notice ",
      link: "/notice",
      icon: <i className="nav-icon fas fa-bullhorn"></i>,
      target: "notice",
    },
    {
      name: "Transfer ",
      link: "/transfer/add",
      icon: <i className="nav-icon fas fa-exchange-alt"></i>,
      target: "transfer",
    },
    {
      name: "Profile",
      link: "/profile",
      icon: <i className="nav-icon fas fa-user-alt"></i>,
      target: "profile",
    },

    {
      name: "Waste",
      link: "/waste",
      icon: <i className="nav-icon fas fa-trash-alt"></i>,
      target: "waste",
    },

    {
      name: "Requisition",
      link: "/requisition/add",
      icon: <i className="nav-icon fas fa-clipboard-list"></i>,
      target: "requisition",
    },
    {
      name: "Configure",
      link: "/settings",
      icon: <i className="nav-icon fas  fa-tools"></i>,
      target: "settings",
    },
  ];
  return (
    <aside
      className="main-sidebar sidebar-dark-primary elevation-4"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100%",
        width: "250px",
        zIndex: 1000,
      }}
    >
      <a
        href="/dashboard"
        className="brand-link text-decoration-none text-center"
      >
        <span className="brand-text fw-bold">
          {settings.application_name || "ERP"}
        </span>
      </a>

      {/* Sidebar Menu */}
      <div className="sidebar">
        <nav className="mt-2 mb-3">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {filterAccess(user?.user?.role, menu).map((item, i) => (
              <li className="nav-item" key={i} onClick={() => setCurrent(i)}>
                <Link
                  className={`nav-link ${
                    current === i ? "active" : ""
                  } custom-nav`}
                  title={item.name}
                  to={item.link}
                  role="button"
                >
                  {item.icon}
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
