import React, { useState, useEffect, useContext, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom"; // Import Link
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import UpdateModal from "./UpdateModal";
import axios from "../../api/axios";
import AssignUserRoleModal from "./AssignUserRoleModal"; // Import AssignUserRoleModal
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import routesConfig from "../../routesConfig"; // Adjust the import path accordingly
import AssignUserModal from "./AssignUserRoleModal";

import { AuthContext } from "../../context/auth";
import Error403 from "../../components/Error/403";
import DataTable from "../../components/table";

export const AllUserIndexAdd = () => {
  const formatPageName = (path) => {
    return (
      path.substring(1).replace("/", " ").charAt(0).toUpperCase() +
      path.substring(2).replace("/", " ")
    );
  };
  const [showAssignUserModal, setShowAssignUserModal] = useState(false); // Define showAssignUserModal state

  const [showPassword, setShowPassword] = useState(false);
  const [isInputFocused, setInputFocused] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState({
    viewer: false,
    editor: false,
  });
  const [pageSwitches, setPageSwitches] = useState({});

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    phone: "",
    store_name: "",
    origin: "",
    address: "",
    password: "",
    confirmPassword: "",
    zipCode: "",
  });

  useEffect(() => {
    // Initialize page switches state
    const switches = {};
    routesConfig.forEach((route) => {
      switches[route.path] = { switch1: false, switch2: false };
    });
    setPageSwitches(switches);
  }, []);
  const { user } = useContext(AuthContext);
  if (user) {
    const access = user.user?.role.find((role) => role.name === "allUser");
    if (!access.allow) {
      return <Error403 />;
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  // const handleSwitchChange = (page, switchName) => (e) => {
  //   setPageSwitches({
  //     ...pageSwitches,
  //     [page]: {
  //       ...pageSwitches[page],
  //       [switchName]: e.target.checked,
  //     },
  //   });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const addressWithZip = formData.zipCode
        ? `${formData.address}, ${formData.zipCode}`
        : formData.address;

      const updatedFormData = {
        ...formData,
        address: addressWithZip,
        role: pageSwitches,
      };

      const response = await axios.post("/add-register", updatedFormData);
      if (response.status === 201) {
        toast.success("Registration successful");
        setFormData({
          username: "",
          email: "",
          phone: "",
          store_name: "",
          origin: "",
          address: "",
          password: "",
          zipCode: "",
          confirmPassword: "",
        });
        // setSelectedRoles({
        //   viewer: false,
        //   editor: false,
        // });
        setPageSwitches([]);
      } else {
        toast.error("Failed to register");
      }
    } catch (error) {
      console.error("Error registering:", error);
      toast.error("Failed to register");
    }
  };

  return (
    <div className="container">
      <div className="">
        <div className="card shadow p-3 mb-5 bg-body rounded ">
          <div className="card-header">
            <h3 className="card-title">Add User</h3>
          </div>
          <div className="card-body mb-4">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="username">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <input
                      className="form-control"
                      id="address"
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="zipCode">Zip Code</label>
                    <input
                      className="form-control"
                      id="zipCode"
                      type="text"
                      name="zipCode"
                      value={formData.zipCode}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="origin">Country</label>
                    <input
                      className="form-control"
                      id="origin"
                      type="text"
                      name="origin"
                      value={formData.origin}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="store_name">Store Name</label>
                    <input
                      className="form-control"
                      id="store_name"
                      type="text"
                      name="store_name"
                      value={formData.store_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="phone">Phone Number</label>
                    <input
                      className="form-control"
                      id="phone"
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      onFocus={handleInputFocus}
                      onBlur={handleInputBlur}
                      required
                      className="form-control"
                    />
                    <button
                      type="button"
                      className="toggle-password icon-button"
                      onClick={togglePasswordVisibility}
                    >
                      <FontAwesomeIcon
                        icon={showPassword ? faEye : faEyeSlash}
                      />
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                      className="form-control"
                      type="password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <button
                    type="button"
                    className="btn btn-primary mt-2"
                    onClick={() => setShowAssignUserModal(true)}
                  >
                    Assign Pages
                  </button>
                </div>
                <div className="col-md-6">
                  <button type="submit" className="btn btn-primary mt-2">
                    Register
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* AssignUserModal */}
      <AssignUserModal
        show={showAssignUserModal}
        handleClose={() => setShowAssignUserModal(false)}
        pageSwitches={setPageSwitches}
        // handleSwitchChange={handleSwitchChange}
        formatPageName={formatPageName}
        routesConfig={routesConfig}
      />
    </div>
  );
};



export const AllUserIndexGet = () => {
  const [userLists, setUserLists] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserName, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { user } = useContext(AuthContext);
  const table = useRef(null);

  const itemsPerPage = 15;

  const fetchUserlists = async () => {
    try {
      const response = await axios.get(`/get-user?page=${currentPage}&itemsPerPage=${itemsPerPage}`);
      setUserLists(response.data.userLists || []);
      setTotalItems(response.data.pagination.totalItems || 0);
    } catch (error) {
      console.error("Error fetching User Lists:", error);
    }
  };

  useEffect(() => {
    fetchUserlists();
  }, [currentPage]);

  if (user) {
    const access = user.user?.role.find((role) => role.name === "allUser");
    if (!access || !access.allow) {
      return <Error403 />;
    }
  }

  const handleDelete = (user_id) => {
    setSelectedUserId(user_id);
    setShowDeleteModal(true);
  };

  const handleAssignRole = (user_id, username, userRole) => {
    setSelectedUserId(user_id);
    setSelectedUser({ username, userRole });
    setShowUpdateModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete("/delete-user", {
        data: { user_id: selectedUserId },
      });
      toast.success("User deleted successfully");
      fetchUserlists();
    } catch (error) {
      console.error("Error deleting User:", error);
      toast.error("Failed to delete user");
    } finally {
      setShowDeleteModal(false);
    }
  };

  const filteredUserLists = userLists.filter((userList) => {
    const searchTerms = searchTerm.toLowerCase();
    const {
      user_id,
      username,
      email,
      phone,
      store_name,
      origin,
      address,
    } = userList;

    return (
      (user_id && user_id.toString().includes(searchTerms)) ||
      (username && username.toLowerCase().includes(searchTerms)) ||
      (email && email.toLowerCase().includes(searchTerms)) ||
      (phone && phone.toLowerCase().includes(searchTerms)) ||
      (store_name && store_name.toLowerCase().includes(searchTerms)) ||
      (origin && origin.toLowerCase().includes(searchTerms)) ||
      (address && address.toLowerCase().includes(searchTerms))
    );
  });

  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded table-responsive">
                <div className="row mb-2">
                  <div className="col-md-6"></div>
                  <div className="col-md-6">
                    <Link to="/all-user/add" className="btn btn-primary float-right mr-2">
                      Add User
                    </Link>
                  </div>
                </div>
                <div className="card-header mt-2">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="card-title">All User List</h3>
                    </div>
                  </div>
                </div>
                <div className="row mt-3 py-3">
                  <div className="col-md-8"></div>
                  <div className="col-md-4">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {userLists.length > 0 && (
                    <DataTable
                      data={filteredUserLists}
                      ref={table}
                      customPaginate
                      setCurrentPage={setCurrentPage}
                      totalItems={totalItems}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">User Id</th>
                          <th className="text-center">User Name</th>
                          <th className="text-center">Email</th>
                          <th className="text-center">Phone Number</th>
                          <th className="text-center">Store Name</th>
                          <th className="text-center">Address</th>
                          <th className="text-center">Origin</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredUserLists.map((userList) => (
                          <tr key={userList.user_id}>
                            <td className="text-secondary text-center">{userList.user_id}</td>
                            <td className="text-secondary text-center">{userList.username}</td>
                            <td className="text-secondary text-center">{userList.email}</td>
                            <td className="text-secondary text-center">{userList.phone}</td>
                            <td className="text-secondary text-center">{userList.store_name}</td>
                            <td className="text-secondary text-center">{userList.address}</td>
                            <td className="text-secondary text-center">{userList.origin}</td>
                            <td className="text-secondary text-center">
                              <div className="d-flex">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => handleAssignRole(userList.user_id, userList.username, JSON.parse(userList.role))}
                                  title="Assign Role"
                                >
                                  <i className="fas fa-key mr-1"></i>
                                </button>
                                <button
                                  className="btn btn-danger btn-sm ml-2"
                                  onClick={() => handleDelete(userList.user_id)}
                                >
                                  <i className="fas fa-trash-alt mr-1"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <DeleteConfirmationModal
        userlistIdToDelete={selectedUserId}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      />

      <UpdateModal
        show={showUpdateModal}
        handleClose={() => setShowUpdateModal(false)}
        userRoles={selectedUserName?.userRole}
        userId={selectedUserId}
        updateData={fetchUserlists}
      />
    </div>
  );
};


export const AssignRoleUser = () => {
  // const [roleUserList, setRoleUserList] = useState([]);
  // const fetchRoleUserList = async () => {
  //   try {
  //     const response = await axios.get("/get-role-user");
  //     setRoleUserList(response.data);
  //   } catch (error) {
  //     console.error("Error fetching Role User List:", error);
  //   }
  // };
  // useEffect(() => {
  //   fetchRoleUserList();
  // }, []);
  // const getUsername = async (userId) => {
  //   try {
  //     const response = await axios.get("/get-user/${userId}");
  //     return response.data.username;
  //   } catch (error) {
  //     console.error("Error fetching username:", error);
  //     return "";
  //   }
  // };
  // const getRoleName = async (roleId) => {
  //   try {
  //     const response = await axios.get("/get-role/${roleId}");
  //     return response.data.role_name;
  //   } catch (error) {
  //     console.error("Error fetching role name:", error);
  //     return "";
  //   }
  // };
  // return (
  //   // <div className="content">
  //   //   <section className="content">
  //   //     <div className="container">
  //   //       <div className="row">
  //   //         <div className="col-md-12">
  //   //           <h3 className="mt-3">Role User List</h3>
  //   //           <table className="table table-bordered table-hover">
  //   //             <thead>
  //   //               <tr>
  //   //                 <th>User ID</th>
  //   //                 <th>Username</th>
  //   //                 <th>Role ID</th>
  //   //                 <th>Role Name</th>
  //   //               </tr>
  //   //             </thead>
  //   //             <tbody>
  //   //               {roleUserList.map(async (roleUser) => {
  //   //                 const username = await getUsername(roleUser.user_id);
  //   //                 const roleName = await getRoleName(roleUser.role_id);
  //   //                 return (
  //   //                   <tr key={roleUser.id}>
  //   //                     <td>{roleUser.user_id}</td>
  //   //                     <td>{username}</td>
  //   //                     <td>{roleUser.role_id}</td>
  //   //                     <td>{roleName}</td>
  //   //                   </tr>
  //   //                 );
  //   //               })}
  //   //             </tbody>
  //   //           </table>
  //   //         </div>
  //   //       </div>
  //   //     </div>
  //   //   </section>
  //   //   <ToastContainer />
  //   // </div>
  //   <h1>Under Development</h1>
  // );
};
