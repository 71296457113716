import React, { useState, useEffect } from "react";
import axios from "../../api/axios";

const TopSellingItemsRecentMonth = () => {
  const [topMarbles, setTopMarbles] = useState([]);
  const [topGranites, setTopGranites] = useState([]);

  useEffect(() => {
    fetchTopItems();
  }, []);

  const fetchTopItems = async () => {
    try {
      // Fetch data for top selling marbles
      // const marbleResponse = await fetch("api/topsells/marbles/recentmonth");
      // const marbleData = await marbleResponse.json();
      // setTopMarbles(marbleData.slice(0, 3)); // Display only the top 3 marbles

      // Fetch data for top selling granites
      const sellsResponse = await axios.get("/get-month-sell");
      const sellsData = sellsResponse.data;

      // Fetch inventory data
      const productResponse = await axios.get("/get-inventory");
      const inventoryData = productResponse.data;
      console.log("Inventory Data:", inventoryData);

      const topGraniteSells = sellsData
        .sort((a, b) => b.sales - a.sales)
        .slice(0, 3);

      // Map product_id to product name
      const topGranitesWithNames = topGraniteSells.map((granite) => {
        let productNames = "Unknown"; // Default to "Unknown" if product_id is not an array or is empty
        if (
          Array.isArray(granite.product_id) &&
          granite.product_id.length > 0
        ) {
          productNames = granite.product_id
            .map((productId) => {
              const product = inventoryData.find(
                (item) => item.inventory_id === productId
              );
              return product ? product.product_name : "Unknown";
            })
            .join(", ");
        }
        return {
          ...granite,
          names: productNames,
        };
      });
      setTopGranites(topGranitesWithNames);
    } catch (error) {
      console.error("Error fetching top selling items:", error);
    }
  };

  return (
    <div>
      <div className="row">
        {/* <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              Top Selling Marbles
            </div>
            <div className="card-body">
              <ul className="list-group">
                {topMarbles.length > 0
                  ? topMarbles.map((marble, index) => (
                      <li
                        key={index}
                        className="list-group-item d-flex justify-content-between align-items-center"
                      >
                        <div>
                          <span className="font-weight-bold">
                            {marble.id}
                          </span>
                        </div>
                      </li>
                    ))
                  : "No data available"}
              </ul>
            </div>
          </div>
        </div> */}

        <div className="col-md-12">
          <div className="card">
            <div className="card-header">Top Selling Item</div>
            <div className="card-body">
              <ul className="list-group">
                {topGranites.length > 0
                  ? topGranites.map((granite, index) => (
                      <li
                        key={index}
                        className="list-group-item d-flex justify-content-between align-items-center"
                      >
                        <div>
                          <span className="font-weight-bold">
                            {granite.name} (ID: {granite.product_id})
                          </span>
                        </div>
                      </li>
                    ))
                  : "No data available"}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSellingItemsRecentMonth;
