import React, { useState, useEffect, useContext, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../api/axios";
import ScheduleNotice from "./ScheduleNoticeModal";
import RichTextEditor from "./ReactDraft";
import { Link } from "react-router-dom"; // Import Link
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { AuthContext } from "../../context/auth";
import NoticeDetailsModal from "./NoticeDetailsModal"; // Import your modal component here
import Error403 from "../../components/Error/403";
import DataTable from "../../components/table";

export const NoticeIndexAdd = (loggedInUser) => {
  const [formData, setFormData] = useState({
    notice_title: "",
    notice_description: "",
    publish_date_time: "",
    notice_id: "",
    user_id: "",
    user_name: "",
  });

  const [showScheduleModal, setShowScheduleModal] = useState(false); // State to control the visibility of the Schedule Notice modal

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const { user } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Extract user_id from loggedInUser prop

      // Check if publish_date_time is null
      let publishDateTime = formData.publish_date_time;
      if (!publishDateTime) {
        // If null, set publishDateTime to current date and time plus 60 seconds
        const currentDate = new Date();
        currentDate.setSeconds(currentDate.getSeconds() + 60);
        publishDateTime = currentDate.toISOString();
      }

      const response = await axios.post("/add-notice", {
        ...formData,
        user_id: loggedInUser.loggedInUser, // Access the user_id property of loggedInUser
        user_name: user?.user.name,
        publish_date_time: publishDateTime,
      });
      console.log("Notice added successfully:", response.data);
      toast.success("Notice added successfully");
      setFormData({
        notice_title: "",
        notice_description: "",
        publish_date_time: "",
        notice_id: "",
        user_id: "",
        user_name: "",
      });
    } catch (error) {
      console.error("Error adding notice:", error);
      toast.error("Failed to add notice");
    }
  };

  if (user) {
    const access = user.user?.role.find((role) => role.name === "notice");
    if (!access.allow) {
      return <Error403 />;
    }
  }

  return (
    <div className="">
      <section className="content">
        <div className="container">
          <div className="row mt-2">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="card shadow p-3 mb-5 bg-body rounded ">
                <div className="card-header">
                  <h3 className="card-title">Add Notice</h3>
                </div>
                <div className="card-body mb-4">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="notice_title">Notice Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter notice title"
                        id="notice_title"
                        name="notice_title"
                        value={formData.notice_title}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="notice_description">
                        Notice Description
                      </label>
                      <RichTextEditor
                        value={null}
                        onChange={(value) =>
                          setFormData({
                            ...formData,
                            notice_description: value,
                          })
                        }
                      />
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <button type="submit" className="btn btn-primary mr-2">
                          Add Now
                        </button>
                      </div>
                      {/* <div className="col-md-6">
                        <button
                          type="button"
                          className="btn btn-primary mr-2 float-right"
                          onClick={() => setShowScheduleModal(true)} // Open the ScheduleNotice modal when this button is clicked
                        >
                          Schedule Notice
                        </button>
                      </div> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScheduleNotice // Render the ScheduleNotice modal
        formData={formData} // Pass form data to ScheduleNotice component
        show={showScheduleModal} // Pass the visibility state to the modal
        onClose={() => setShowScheduleModal(false)} // Function to close the modal
        handleInputChange={handleInputChange} // Pass handleInputChange function to handle changes in modal inputs
        loggedInUser={loggedInUser.loggedInUser} // Make sure this prop is being passed correctly
      />
    </div>
  );
};

export const NoticeIndexGet = () => {
  const [notices, setNotices] = useState([]);
  const [noticeToUpdate, setNoticeToUpdate] = useState(null);
  const [noticeIdToDelete, setNoticeIdToDelete] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const { user } = useContext(AuthContext);

  const table = useRef(null);

  const itemsPerPage = 15;

  const fetchNotices = async () => {
    try {
      const response = await axios.get(
        `/get-notice?page=${currentPage}&itemsPerPage=${itemsPerPage}`
      );
      setNotices(response.data?.notices || []);
    } catch (error) {
      console.error("Error fetching notice:", error);
    }
  };

  useEffect(() => {
    fetchNotices();
  }, [currentPage]);
  if (user) {
    const access = user.user?.role.find((role) => role.name === "notice");
    if (!access.allow) {
      return <Error403 />;
    }
  }

  const handlePublish = async (notice) => {
    try {
      const response = await axios.post("/publish-notice", {
        notice_id: notice.notice_id, // Make sure notice_id is correctly passed
        publish_datetime: null, // Set publishDateTime as null to publish immediately
        notice_status: notice.notice_status,
      });

      if (response.status === 200) {
        console.log("Notice published successfully:", response.data);
        toast.success("Notice published successfully");

        // Refresh notices after publishing
        fetchNotices();
      }
    } catch (error) {
      console.error("Error publishing notice:", error);
      toast.error("Failed to publish notice");
    }
  };

  const handleDelete = (notice) => {
    setNoticeIdToDelete(notice.notice_id);
    setShowDeleteModal(true);
  };

  // const handleEdit = (notice) => {
  //   setNoticeToUpdate(notice);
  //   setShowUpdateModal(true);
  // };

  // const handleConfirmUpdate = async () => {
  //   try {
  //     const response = await axios.put("/update-category", {
  //       data: {
  //         unique_category_id: categoryToUpdate.unique_category_id,
  //         category_name: categoryToUpdate.category_name,
  //       },
  //     });

  //     if (response.status === 200) {
  //       console.log("Category updated successfully:", response.data);
  //       toast.success("Category updated successfully");

  //       fetchCategories();

  //       setShowUpdateModal(false);
  //     }
  //   } catch (error) {
  //     console.error("Error updating category:", error);
  //     toast.error("Failed to update category");
  //   }
  // };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete("delete-notice", {
        data: { notice_id: noticeIdToDelete },
      });
      console.log("Notice deleted successfully:", response.data);
      fetchNotices();
      toast.success("Notice deleted successfully");
    } catch (error) {
      console.error("Error deleting notice:", error);
      toast.error("Failed to delete notice");
    } finally {
      setShowDeleteModal(false);
    }
  };

  const filteredNotice = notices.filter((notice) => {
    const {
      notice_id,
      user_id,
      notice_title,
      notice_description,
      notice_status,
      publish_datetime,
    } = notice;
    const searchTerms = searchTerm.toLowerCase();

    // Convert each field to string and make it lowercase
    const id = notice_id ? notice_id.toString() : "";
    const userId = user_id ? user_id.toString().toLowerCase() : "";
    const noticeTitle = notice_title
      ? notice_title.toString().toLowerCase()
      : "";
    const noticeDescription = notice_description
      ? notice_description.toString().toLowerCase()
      : "";
    const noticeStatus = notice_status
      ? notice_status.toString().toLowerCase()
      : "";
    const publishDatetime = publish_datetime
      ? publish_datetime.toString().toLowerCase()
      : "";

    // Check if any of the fields contain the search term
    return (
      id.includes(searchTerms) ||
      userId.includes(searchTerms) ||
      noticeTitle.includes(searchTerms) ||
      noticeDescription.includes(searchTerms) ||
      noticeStatus.includes(searchTerms) ||
      publishDatetime.includes(searchTerms)
    );
  });

  const getStatusBadge = (status) => {
    let badgeClass = "";
    switch (status) {
      case "unpublished":
        badgeClass = "badge-warning";
        break;
      case "published":
        badgeClass = "badge-success";
        break;
      case "Draft":
        badgeClass = "badge-secondary";
        break;
      default:
        badgeClass = "badge-info";
    }
    return <span className={`badge ${badgeClass}`}>{status}</span>;
  };
  const handleNoticeClick = (notice) => {
    setSelectedNotice(notice);
    setShowModal(true);
  };

  const MAX_DESCRIPTION_LENGTH = 100; // Maximum length of the description to show initially

  const truncateDescription = (description) => {
    if (description.length > MAX_DESCRIPTION_LENGTH) {
      return description.substring(0, MAX_DESCRIPTION_LENGTH) + "..."; // Truncate the description if it exceeds the maximum length
    }
    return description;
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="row mb-2">
                  <div className="col-md-6"></div>
                  <div className="col-md-6">
                    <Link
                      to="/notice/add"
                      className="btn btn-primary float-right mr-2"
                    >
                      Add Notice
                    </Link>
                  </div>
                </div>
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="card-title">All Notice</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mb-3 py-3">
                    <div className="col-md-8"></div>
                    <div className="col-md-4">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {notices.length > 0 && (
                    <DataTable
                      data={notices}
                      customPaginate
                      setCurrentPage={setCurrentPage}
                      totalItems={totalItems}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">Notice ID</th>
                          <th className="text-center">User Id</th>
                          <th className="text-center">User Name</th>
                          <th className="text-center">Notice Title</th>
                          <th className="text-center">Notice Description</th>
                          <th className="text-center">Notice Status</th>
                          <th className="text-center">Publish Date & Time</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredNotice.map((notice) => (
                          <tr>
                            <td className="text-secondary text-center">
                              {notice.notice_id}
                            </td>
                            <td className="text-secondary text-center">
                              {notice.user_id}
                            </td>
                            <td className="text-secondary text-center">
                              {notice.user_name || "N/A"}
                            </td>
                            <td className="text-secondary text-center">
                              {notice.notice_title}
                            </td>
                            {/* <td className="text-secondary text-center">
                              {notice.notice_description}
                            </td> */}
                            {/* <td
                              className="text-secondary text-center"
                              dangerouslySetInnerHTML={{
                                __html: notice.notice_description,
                              }}
                            ></td> */}
                            <td className="text-secondary text-center">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: showFullDescription
                                    ? notice.notice_description
                                    : truncateDescription(
                                        notice.notice_description
                                      ),
                                }}
                              />
                              {!showFullDescription &&
                                notice.notice_description.length >
                                  MAX_DESCRIPTION_LENGTH && (
                                  <button
                                    className="btn btn-link"
                                    onClick={toggleDescription}
                                  >
                                    Show More
                                  </button>
                                )}
                            </td>
                            <td className="text-secondary text-center">
                              {getStatusBadge(notice.notice_status)}
                            </td>
                            <td className="text-secondary text-center">
                              {new Date(
                                notice.publish_datetime
                              ).toLocaleString()}
                            </td>
                            <td className="text-secondary text-center">
                              <div className="">
                                <button
                                  className="btn btn-primary btn-sm"
                                  title="Publish Now"
                                  onClick={() => handlePublish(notice)}
                                >
                                  <i className="fas fa-bullhorn"></i>
                                </button>

                                <button
                                  className="btn btn-danger btn-sm"
                                  title="Delete"
                                  onClick={() => handleDelete(notice)}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <UpdateModal
        category={categoryToUpdate}
        show={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        onConfirm={handleConfirmUpdate}
      /> */}
      <DeleteConfirmationModal
        noticeIdToDelete={noticeIdToDelete}
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleConfirmDelete}
      />
      {showModal && (
        <NoticeDetailsModal
          notice={selectedNotice}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export const NoticeIndexRead = () => {
  const [notices, setNotices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const { user } = useContext(AuthContext);

  const itemsPerPage = 15;

  const fetchNotices = async () => {
    try {
      const response = await axios.get(
        `/get-notice?page=${currentPage}&itemsPerPage=${itemsPerPage}`
      );
      setNotices(response.data?.notices || []);
      setTotalItems(response.data?.pagination?.totalItems || 0);
    } catch (error) {
      console.error("Error fetching notice:", error);
    }
  };

  useEffect(() => {
    fetchNotices();
  }, [currentPage]);

  if (user) {
    const access = user.user?.role.find((role) => role.name === "noticeRead");
    if (!access || !access.allow) {
      return <Error403 />;
    }
  }

  const filteredNotice = notices.filter((notice) => {
    const {
      notice_id,
      user_id,
      notice_title,
      notice_description,
      notice_status,
      publish_datetime,
    } = notice;

    // Filter out non-published notices
    if (notice_status.toLowerCase() !== "published") {
      return false;
    }

    const searchTerms = searchTerm.toLowerCase();

    // Convert each field to string and make it lowercase
    const id = notice_id ? notice_id.toString() : "";
    const userId = user_id ? user_id.toString().toLowerCase() : "";
    const noticeTitle = notice_title
      ? notice_title.toString().toLowerCase()
      : "";
    const noticeDescription = notice_description
      ? notice_description.toString().toLowerCase()
      : "";
    const publishDatetime = publish_datetime
      ? publish_datetime.toString().toLowerCase()
      : "";

    // Check if any of the fields contain the search term
    return (
      id.includes(searchTerms) ||
      userId.includes(searchTerms) ||
      noticeTitle.includes(searchTerms) ||
      noticeDescription.includes(searchTerms) ||
      publishDatetime.includes(searchTerms)
    );
  });

  const handleNoticeClick = (notice) => {
    setSelectedNotice(notice);
    setShowModal(true);
  };

  return (
    <div className="content">
      <section className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card shadow p-3 mb-5 bg-body rounded">
                <div className="row mb-2">
                  <div className="col-md-6"></div>
                </div>
                <div className="card-header">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className="card-title">All Notice</h3>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row mb-3 py-3">
                    <div className="col-md-8"></div>
                    <div className="col-md-4">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {notices.length > 0 && (
                    <DataTable
                      data={filteredNotice}
                      customPaginate
                      setCurrentPage={setCurrentPage}
                      totalItems={totalItems}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                    >
                      <thead>
                        <tr>
                          <th className="text-center">Notice ID</th>
                          <th className="text-center">User Id</th>
                          <th className="text-center">Notice Title</th>
                          <th className="text-center">Notice Description</th>
                          <th className="text-center">Publish Date & Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredNotice.map((notice) => (
                          <tr
                            key={notice.notice_id}
                            onClick={() => handleNoticeClick(notice)}
                          >
                            <td className="text-secondary text-center">
                              {notice.notice_id}
                            </td>
                            <td className="text-secondary text-center">
                              {notice.user_id}
                            </td>
                            <td className="text-secondary text-center">
                              {notice.notice_title}
                            </td>
                            <td
                              className="text-secondary text-center"
                              dangerouslySetInnerHTML={{
                                __html: notice.notice_description
                                  .split("\n")
                                  .slice(0, 2)
                                  .join("<br/>"), // Splitting and joining the first two lines with line breaks
                              }}
                            ></td>
                            <td className="text-secondary text-center">
                              {new Date(
                                notice.publish_datetime
                              ).toLocaleString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </DataTable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showModal && (
        <NoticeDetailsModal
          notice={selectedNotice}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};
