import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ImageLoader = ({
  alt,
  height,
  width,
  src,
  className,
  style,
  staticFile,
}) => (
  <div>
    <LazyLoadImage
      alt={alt || src}
      height={height || "auto"}
      src={`${process.env.REACT_APP_API_ENDPOINT}/api/uploads/${src}`}
      width={width || "100%"}
      className={className}
      style={style}
    />
  </div>
);

export default ImageLoader;
