import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateModal = ({ category, show, onClose }) => {
  const [updatedCategory, setUpdatedCategory] = useState({
    unique_category_id: "",
    category_name: "",
  });

  // Populate the state with the current category values when it's available
  useEffect(() => {
    if (category) {
      setUpdatedCategory({
        unique_category_id: category.unique_category_id,
        category_name: category.category_name,
      });
    }
  }, [category]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedCategory({
      ...updatedCategory,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send PUT request to update category
      const response = await axios.put("/update-category", updatedCategory);
      console.log("Category updated successfully:", response.data);
      onClose();
      toast.success("Category updated successfully");
    } catch (error) {
      console.error("Error updating category:", error);
      toast.error("Failed to update category");
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="unique_category_id">Category ID</label>
            <input
              type="text"
              className="form-control"
              id="unique_category_id"
              name="unique_category_id"
              value={updatedCategory.unique_category_id}
              disabled
            />
          </div>

          <div className="form-group">
            <label htmlFor="category_name">Category Name</label>
            <input
              type="text"
              className="form-control"
              id="category_name"
              name="category_name"
              value={updatedCategory.category_name}
              onChange={handleInputChange}
              required
            />
          </div>
          <Button variant="primary" type="submit">
            Update Category
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateModal;
