import React, { useState } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "../style.css"; // Import your CSS file

const RichTextEditor = ({ value, onChange }) => {
  const [editorState, setEditorState] = useState(
    value ? EditorState.createWithContent(convertFromRaw(value)) : EditorState.createEmpty()
  );

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    onChange(draftToHtml(convertToRaw(newEditorState.getCurrentContent())));
  };

  return (
    <div className="card" style={{ minHeight: '210px' }}>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        toolbar={{
          options: ['inline', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'remove', 'history', ],
          fontFamily: {
            options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Verdana'],
          },
          fontSize: {
            options: [8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96],
          },
        }}
      />
    </div>
  );
};

export default RichTextEditor;
