import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "admin-lte/dist/css/adminlte.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/axios";
import { AuthContext } from "../context/auth";

const Login = ({ onLogin }) => {
  const navigate = useNavigate();
  const [user_id, setUser_id] = useState("");
  const [password, setPassword] = useState("");
  const [loggedInUser, setLoggedInUser] = useState(null); // Track logged-in user
  const { login } = useContext(AuthContext);

  const handleUser_idChange = (e) => {
    setUser_id(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios
        .post("/login", {
          user_id,
          password,
        })
        .then((r) => r.data);
      console.log(response);
      if (response?.success) {
        // Call onLogin with the user_id upon successful login
        onLogin(user_id);
        login({ user: response.user });
        toast.success("Login successful");
        // window.location.href = '/';
        navigate("/dashboard");
      } else {
        toast.error(response.message || "Failed to login");
      }
    } catch (error) {
      console.error("Login Error:", error);
      toast.error("Failed to login");
    }
  };

  return (
    <div className="container">
      <div className="login-box">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="user-box">
            <input
              type="text"
              name="user_id"
              value={user_id}
              onChange={handleUser_idChange}
              required
            />
            <label>User Id</label>
          </div>
          <div className="user-box">
            <input
              type="password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <label>Password</label>
          </div>
          {/* <div className="row">
            <Link to="/register">Register here</Link>
          </div> */}
          {loggedInUser && <div>Logged in as: {loggedInUser}</div>}
          <button type="submit" className="btn btn-primary mt-2">
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
