import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css"; // You can change the theme if needed
import axios from "../../api/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateModal = ({ requisition, show, onClose }) => {
  const [updatedRequisition, setUpdatedRequisition] = useState({
    requisition_id: "",
    product_name: "",
    startDate: "",
    endDate: "",
    quantity: "",
    unique_category_id: "",
    user_id: ""
  });

  const [dateRange, setDateRange] = useState([null, null]);

  useEffect(() => {
    if (requisition) {
      setUpdatedRequisition({
        requisition_id: requisition.requisition_id,
        product_name: requisition.product_name,
        startDate: requisition.startDate,
        endDate: requisition.endDate,
        quantity: requisition.quantity,
        unique_category_id: requisition.unique_category_id,
        user_id: requisition.user_id
      });

      if (requisition.startDate && requisition.endDate) {
        setDateRange([new Date(requisition.startDate), new Date(requisition.endDate)]);
      }
    }
  }, [requisition]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedRequisition({
      ...updatedRequisition,
      [name]: value,
    });
  };

  const handleDateChange = (selectedDates) => {
    const [start, end] = selectedDates;
    setDateRange(selectedDates);
    setUpdatedRequisition({
      ...updatedRequisition,
      startDate: start ? start.toISOString() : "",
      endDate: end ? end.toISOString() : "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    try {
      const response = await axios.put('/update-requisition', {
        requisition_id: updatedRequisition.requisition_id,
        product_name: updatedRequisition.product_name,
        startDate: updatedRequisition.startDate,
        endDate: updatedRequisition.endDate,
        quantity: updatedRequisition.quantity,
        unique_category_id: updatedRequisition.unique_category_id,
        user_id: updatedRequisition.user_id
      });

      console.log('Requisition updated successfully:', response.data);
      toast.success("Requisition updated successfully");
      onClose(); // Close the modal
    } catch (error) {
      console.error('Error updating requisition:', error);
      toast.error("Error updating requisition");
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Requisition</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="requisition_id">Requisition ID</label>
            <input
              type="text"
              className="form-control"
              id="requisition_id"
              name="requisition_id"
              value={updatedRequisition.requisition_id}
              disabled
            />
          </div>
          <div className="form-group">
            <label htmlFor="requisition_id">Requisition Request User ID</label>
            <input
              type="text"
              className="form-control"
              id="user_id"
              name="user_id"
              value={updatedRequisition.user_id}
              disabled
            />
          </div>
          <div className="form-group">
            <label htmlFor="product_name">Product Name</label>
            <input
              type="text"
              className="form-control"
              id="product_name"
              name="product_name"
              value={updatedRequisition.product_name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Urgency Date</label>
            <div className="form-control p-0">
              <Flatpickr
                options={{ mode: "range" }}
                value={dateRange}
                onChange={handleDateChange}
                className="form-control"
                placeholder="Select Date Range"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="quantity">Quantity</label>
            <input
              type="number"
              className="form-control"
              id="quantity"
              name="quantity"
              value={updatedRequisition.quantity}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="unique_category_id">Category</label>
            <input
              type="text"
              className="form-control"
              id="unique_category_id"
              name="unique_category_id"
              value={updatedRequisition.unique_category_id}
              onChange={handleInputChange}
              required
            />
          </div>
          <Button variant="primary" type="submit">
            Update Requisition
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateModal;
